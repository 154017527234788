import { Config, DAppProvider, Mainnet } from '@usedapp/core';
import { UserContextProvider } from 'context/UserContext';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import App from './App';
import { UtilityContextProvider } from './context/UtilityContext';
import './index.css';
import reportWebVitals from './reportWebVitals';
import en from 'javascript-time-ago/locale/en.json';
import TimeAgo from 'javascript-time-ago';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(en);

const config: Config = {
  readOnlyChainId: Mainnet.chainId,
};

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <DAppProvider config={config}>
        <UtilityContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </UtilityContextProvider>
      </DAppProvider>
    </UserContextProvider>
    <ToastContainer position="top-right" />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
