import { NodeFilteringStatus } from 'models/PublicComplaints';

export const alwaysIntIfString = (stringOrNumber: string | number) => {
  if (typeof stringOrNumber === 'number') return stringOrNumber;
  else return parseInt(stringOrNumber);
};

export const getSumByKey = (arr: any, key: any) => {
  return arr.reduce(
    (accumulator: any, current: any) => accumulator + Number(current[key]),
    0
  );
};

export const parseFilteringStatusToString = (
  filteringStatus: NodeFilteringStatus
) => {
  switch (filteringStatus) {
    case NodeFilteringStatus.Unknown:
      return 'Unknown';

    case NodeFilteringStatus.Filtering:
      return 'Filtering';

    case NodeFilteringStatus.NotFiltering:
      return 'Not filtering';
  }
};

export const shortenAddress = (address?: string) => {
  if (address) {
    return (
      address.substring(0, 6) + '...' + address.substring(address.length - 4)
    );
  }

  return '';
};
