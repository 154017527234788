import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { act } from 'react-dom/test-utils';
import { Complaint } from 'models/Complaint';
import { apiFunctions } from 'services/complaints-portal-api.service';
import './Complaint.css';
import { ComplaintForm } from './ComplaintForm/ComplaintForm';

export const ComplaintPage = (props: any): JSX.Element => {
  const object = new Complaint();

  const [complaint, setComplaint] = useState<Complaint>(object);
  const [loaded, setLoaded] = useState<boolean>(false);

  const getComplaintById = async (_id: number) => {
    setComplaint({
      ...complaint,
      _id,
    });
    apiFunctions
      .getComplaintById(_id)
      .then((result) => {
        act(() => {
          setComplaint({ ...result });
          setLoaded(true);
        });
      })
      .catch((err) => {
        console.log(err);
        act(() => {
          setLoaded(true);
        });
      });
  };

  useEffect(() => {
    if (
      props.match &&
      props.match.params &&
      props.match.params._id &&
      !complaint._id
    ) {
      getComplaintById(props.match.params._id);
    } else {
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = 'Looking Glass - Complaint';
  }, []);

  return (
    <>
      {' '}
      {loaded && (
        <>
          <Container className="complaint-container">
            <Row lg="12">
              <Col
                className="description-container text-white"
                md={{ span: 4 }}
              >
                <Row aria-label="complaint-page-content">
                  <div className="dashboard-content-first-title mb-24 fw-700">
                    File a complaint
                  </div>
                  <p>
                    To report infringement or abuse on the Filecoin network,
                    please fill out the form on this page accurately and as
                    completely as possible.
                  </p>
                  <p>
                    Filecoin is a distributed file storage protocol, with many
                    storage providers acting as nodes in the network, each of
                    which may have their own content policies.
                  </p>
                  <p>
                    When you file a complaint through this form, an assessor
                    will review the contents of your complaint and the reported
                    CID(s), and make a determination on whether to accept or
                    reject the CID(s) submitted with the complaint.
                  </p>
                  <p>
                    Each resolved complaint results in a publicly available
                    transparency record that includes your name (with contact
                    information redacted), the complaint itself, the submitted
                    CID(s), and any actions taken by the assessor. You will also
                    receive a copy of this record by email.
                  </p>
                  <p>
                    Items accepted by an assessor may be added to a filter list,
                    which storage providers can opt into as subscribers, so that
                    their nodes will filter out the affected CID(s) from storage
                    and retrieval deals on the Filecoin network. Rejected items
                    will be marked as rejected in the public record, along with
                    reasons for rejection, and no action will be taken on them.
                  </p>
                </Row>
              </Col>
              <Col
                aria-label="complaint-page-form-div"
                className="form-container"
                md={{ span: 7, offset: 1 }}
              >
                <ComplaintForm />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};
