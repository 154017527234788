import { Route, Routes, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { Footer } from './common/Footer/Footer';
import { Header } from './common/Header/Header';
import './resources/styles/helper.css';
import { publicRoutes } from './routes/index';

function App() {
  const { pathname } = useLocation();
  const excludeHeaderPaths = ['/'];

  return (
    <div className="app-container d-flex">
      <div className="route-container d-flex flex-column">
        {!excludeHeaderPaths.includes(pathname) && <Header />}
        <Routes>
          {publicRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
        {!excludeHeaderPaths.includes(pathname) && <Footer />}
      </div>
    </div>
  );
}

export default App;
