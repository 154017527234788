import { NetworkType } from './Common';
import { ComplaintType } from './Modules.models';

export interface PublicComplaintsPayload {
  page?: string;
  itemsPerPage?: string;
  orderBy?: string;
  orderDirection?: string;
  network?: string;
  category?: number;
  startingFrom?: string;
  regions?: string[];
  q?: string;
  email?: string;
  assessor?: string;
  showSpam?: 0 | 1;
}
export interface PublicComplaintsDailyStatsPayload {
  category?: number;
  network?: string;
  startingFrom?: string;
  regions?: string[];
  q?: string;
  email?: string;
  assessor?: string;
  showSpam?: 0 | 1;
}

export enum ComplainantType {
  None,
  Individual,
  Organization,
  Government,
}

export enum OnBehalfOfType {
  None,
  Self,
  OtherParty,
}

export enum ComplaintTypeInt {
  Copyright = 1,
  Inappropriate = 2,
  Other = 3,
}

export interface HostedByArrayItem {
  node: string;
  dealId: number;
  filtering: NodeFilteringStatus;
  country?: string;
}

export interface HostedBy {
  items: Array<HostedByArrayItem>;
  filteredPercentage: number;
  unfilteredPercentage: number;
  countryList: Array<string>;
}

export interface Infringement {
  _id: number;
  value: string;
  reason: string;
  accepted: boolean;
  hostedBy: Array<HostedByArrayItem>;
  resync: boolean;
}

export enum Visibility {
  None,
  Private,
  Public,
  Shared,
  Exception,
}

export interface Cid {
  id: number;
  cid: string;
  hashedCid: string;
  refUrl: string;
}

export interface Filter {
  id: number;
  name: string;
  description: string;
  enabled: boolean;
  visibility: Visibility;
  shareId: string;
  cids: Array<Cid>;
}

export interface Provider {
  id: number;
  walletAddressHashed: string;
  country: string;
  businessName: string;
  website: string;
  email: string;
  contactPerson: string;
  address: string;
  nonce: string;
  created: Date;
}

export enum NodeFilteringStatus {
  Unknown,
  Filtering,
  NotFiltering,
}

export enum RedactionReasonOption {
  PersonalData = 'Personal data',
}

export interface Complaint {
  _id: number;
  fullName: string;
  assessor: Provider;
  companyName: string;
  created: string;
  complaintDescription: string;
  redactedComplaintDescription: string;
  redactionReason: RedactionReasonOption | null;
  title: string;
  geoScope: string[];
  type: ComplaintTypeInt;
  typeString?: ComplaintType;
  email: string;
  resolvedOn: string;
  submittedOn: string;
  filterLists: Array<Filter>;
  complainantType: ComplainantType;
  infringements: Array<Infringement>;
  hostedBy: HostedBy;
  isSpam: boolean;
  networks: Array<NetworkType>;
}

export interface AdjustedFilterListsComplaint extends Complaint {
  adjustedFilterLists: Array<Filter | Partial<Filter>>;
}

export interface PublicComplaintsResponse {
  complaints: Array<Complaint>;
  page: number;
  totalPages: number;
  totalCount: number;
}
