import React from 'react';

interface CIDStatusBadgeProps {
  accepted: boolean;
}

function CIDStatusBadge(props: CIDStatusBadgeProps) {
  const { accepted } = props;

  return (
    <div
      style={{
        padding: '2px 8px',
        color: 'white',
        marginLeft: '12px',
        fontSize: '14px',
        lineHeight: '20px',
        borderRadius: '4px',
        backgroundColor: `${accepted ? '#0366D6' : '#D73A49'}`,
      }}
    >
      {accepted ? 'Accepted' : 'Rejected'}
    </div>
  );
}

export default CIDStatusBadge;
