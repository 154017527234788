import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import React, { useLayoutEffect } from 'react';

interface SemiDonutChartProps {
  data: Array<{
    type: string;
    count: string | number;
  }>;
}

export function SemiDonutChart(props: SemiDonutChartProps) {
  useLayoutEffect(() => {
    const data = [...props.data];
    const filteredObject = props.data.find((e) => e.type === 'Filtered');
    const filteredPercentage = filteredObject ? filteredObject.count : '-.-';
    am5.addLicense('AM5C333440217');

    const root = am5.Root.new('chartdiv');

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        startAngle: 80,
        endAngle: 340,
        tooltip: am5.Tooltip.new(root, {
          forceHidden: true,
        }),
        interactive: false,
        interactiveChildren: false,
      })
    );
    chart.events.off('focus');
    chart.events.off('click');

    const series1 = chart.series.push(
      am5percent.PieSeries.new(root, {
        startAngle: 130,
        endAngle: 410,
        valueField: 'count',
        innerRadius: am5.percent(80),
        categoryField: 'type',
        interactive: false,
        interactiveChildren: false,
      })
    );

    series1.events.off('click');

    series1
      .get('colors')
      ?.set('colors', [am5.color(0xffab70), am5.color(0x444d56)]);

    series1.ticks.template.set('forceHidden', true);
    series1.labels.template.set('forceHidden', true);

    series1.slices.template.states.create('active', {
      active: false,
    });

    series1.slices.template.states.create('hover', {
      active: false,
    });

    series1.slices.template.setAll({
      tooltip: am5.Tooltip.new(root, {
        scale: 0.9,
        labelText: '{category}: {value}%',
      }),
    });

    series1.getTooltip()?.set('forceHidden', true);

    chart.seriesContainer.children.push(
      am5.Label.new(root, {
        textAlign: 'center',
        centerY: am5.percent(23.5),
        centerX: am5.p50,
        fill: am5.color(0xffffff),
        text: `[fontSize: 24px; fontWeight: 500; fontFamily: Inter]${filteredPercentage}%[/]\n\n\n[fontSize: 16px; fontWeight: 400; fontFamily: Inter]Filtered[/]`,
      })
    );

    series1.data.setAll(data);

    return () => {
      root.dispose();
    };
  });

  return <div id="chartdiv" style={{ width: '100%', height: '180px' }}></div>;
}
