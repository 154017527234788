import './Footer.css';

export const Footer = (props: any): JSX.Element => {
  return (
    <div className="footer-container d-flex px-48 py-12 justify-content-between mt-48">
      <div className="footer-left-section d-flex align-items-center">
        <div className="footer-text fs-14 lh-20 text-white mr-48">
          <div
            className="footer-link c-pointer no-text-select"
            onClick={() => {
              window.open('https://murmuration.ai/', '_blank');
            }}
          >
            Murmuration Labs @ 2022. All rights reserved
          </div>
        </div>
      </div>
      <div className="footer-right-section d-flex align-items-center">
        <div
          onClick={() => {
            window.open(
              'https://github.com/Murmuration-Labs/bitscreen/blob/master/terms_of_service.md',
              '_blank'
            );
          }}
          className="footer-link mr-12 fs-14 lh-20 text-white c-pointer no-text-select"
        >
          Terms of Service
        </div>
        <div
          onClick={() => {
            window.open(
              'https://github.com/Murmuration-Labs/bitscreen/blob/master/privacy_policy.md',
              '_blank'
            );
          }}
          className="footer-link mr-12 fs-14 lh-20 text-white c-pointer no-text-select"
        >
          Privacy Policy
        </div>
        <div
          onClick={() => {
            window.open(
              'https://github.com/Murmuration-Labs/bitscreen/',
              '_blank'
            );
          }}
          className="footer-link fs-14 lh-20 text-white c-pointer no-text-select"
        >
          GitHub
        </div>
      </div>
    </div>
  );
};
