import { PieChart } from 'common/Charts/Pie';
import { SemiDonutChart } from 'common/Charts/SemiDonut';
import { StackedLineGraph } from 'common/Charts/StackedLine';
import { Footer } from 'common/Footer/Footer';
import 'common/Header/Header.css';
import { AdditionalResources } from 'modules/Common/AdditionalResources/AdditionalResources';
import { HeaderAppSwitcher } from 'common/Header/HeaderAppSwitcher';
import { useComplaintStats } from 'library/hooks/useStats';
import { useRef, useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { Element, Link as ScrollLink } from 'react-scroll';
import * as icons from 'resources/icons';
import './Home.css';
import { NetworkType } from 'models/Common';

export const Home = (props: any): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const hardcodedTimePeriod = 365;

  const query = useRef('');

  const {
    //General Stats Data
    cidsReviewedObject,
    complaintsObject,
    complainantsObject,
    assessorsObject,
    loadingGeneral,

    //Stacked Line Data
    cidsReviewedPerDay,
    complaintsPerDay,
    complainantsPerDay,
    loadingStacked,
  } = useComplaintStats(hardcodedTimePeriod);

  useEffect(() => {
    document.title = 'Looking Glass';
  }, []);

  return (
    <div className="home-container">
      <div className="home-first-page">
        <div className="header-container d-flex px-48 align-items-center justify-content-between">
          <div className="header-left-section h-100 d-flex align-items-center">
            <div
              onClick={() => navigate('/')}
              className="header-logo fs-28 lh-36 fw-700 text-white mr-48 no-text-select d-flex align-items-center cursor-pointer"
            >
              Looking Glass
            </div>
            <div
              onClick={() => navigate('/dashboard')}
              className={`header-dashboard-button fs-14 lh-20 fw-600 text-white mr-24 c-pointer no-text-select h-100 d-flex align-items-center px-1          
              ${
                pathname.includes('/dashboard') &&
                !pathname.includes('complaints')
                  ? 'active-link'
                  : ''
              }
          `}
            >
              Dashboard
            </div>
            <div
              onClick={() => navigate('/records')}
              className={`header-records-button fs-14 lh-20 fw-600 text-white mr-24 c-pointer no-text-select h-100 d-flex align-items-center px-1
              ${pathname.includes('/records') ? 'active-link' : ''}
          `}
            >
              Records
            </div>
            <div
              onClick={() => navigate('/assessors')}
              className={`header-records-button fs-14 lh-20 fw-600 text-white mr-24 c-pointer no-text-select h-100 d-flex align-items-center px-1
          ${pathname.includes('/assessors') ? 'active-link' : ''}
          `}
            >
              Assessors
            </div>
          </div>
          <div className="header-right-section d-flex align-items-center">
            <div
              onClick={() => navigate('/complaint')}
              className={`mr-20 header-records-button fs-14 lh-20 fw-600 text-white c-pointer px-20 btn btn-primary
              ${
                pathname.includes('/complaint') &&
                !pathname.includes('/complaints')
                  ? 'active-link'
                  : ''
              }
              `}
            >
              File a Complaint
            </div>
            <HeaderAppSwitcher />
          </div>
        </div>
        <div className="home-first-page-inner-container d-flex flex-column justify-content-center px-48 pb-40">
          <div className="d-flex flex-column flex-1 justify-content-center">
            <div className="home-first-page-upper-section d-flex flex-column justify-content-between">
              <div className="home-first-page-main-text fs-64 lh-72 fw-800 text-white">
                A window into {` `}
                <span className="ipfs-text">IPFS </span> and {` `}
                <span className="filecoin-text">Filecoin </span>
                network health
              </div>
              {/* commented until ipfs is resolved */}
              {/* <div className="home-first-page-sub-text fs-28 lh-36 text-white">
                Looking Glass aggregates public data about legal complaints,
                requests for removal, and filtering of content on the IPFS and
                Filecoin networks.
              </div> */}
              <div className="home-first-page-sub-text fs-28 lh-36 text-white">
                Looking Glass aggregates public data about legal complaints,
                requests for removal, and filtering of content on the Filecoin
                network.
              </div>
            </div>
            <div className="home-first-page-lower-section p-24 d-flex flex-column justify-content-between">
              <div className="home-first-page-search-input">
                <InputGroup className="search-input-group">
                  <InputGroup.Text
                    className="search-icon-container pt-8 pb-8 pl-12 pr-10"
                    id="search"
                  >
                    <img alt="search-icon" src={icons.searchGrey} />
                  </InputGroup.Text>
                  <Form.Control
                    className="search-input pl-0 no-outline no-box-shadow"
                    placeholder="Search records"
                    aria-label="Search"
                    aria-describedby="search"
                    onChange={(e) => {
                      query.current = e.target.value;
                    }}
                    onKeyDown={(e) => {
                      if (e.code !== 'Enter') return;
                      navigate('/records', {
                        replace: true,
                        state: {
                          query: query.current,
                        },
                      });
                    }}
                  />
                  {/* <InputGroup.Text
                  className="remove-icon-container pt-8 pb-8 pl-12 pr-10"
                  id="search"
                  >
                  {searchQuery && (
                    <img
                    onClick={() => {
                      if (searchQuery) setSearchQuery('');
                    }}
                    className="remove-icon c-pointer no-text-select"
                    src={icons.removeList}
                    />
                    )}
                  </InputGroup.Text> */}
                </InputGroup>
              </div>
              <div className="home-first-page-buttons-container d-flex fs-14 lh-20 fw-600 gap-3">
                <div
                  onClick={() => {
                    navigate('/dashboard');
                  }}
                  className="ipfs-dashboard-button generic-button d-flex justify-content-center align-items-center py-10 px-20 fs-14 lh-20 text-white flex-1"
                >
                  IPFS dashboard
                </div>
                <div
                  onClick={() => {
                    navigate('/dashboard');
                  }}
                  className="filecoin-dashboard-button generic-button d-flex justify-content-center align-items-center py-10 px-20 fs-14 lh-20 text-white flex-1"
                >
                  Filecoin dashboard
                </div>
              </div>
            </div>
          </div>
          <div className="next-page-arrow c-pointer no-text-select">
            <ScrollLink to="home-second-page" spy={true} duration={500}>
              <img
                alt="next-page-scroll-icon"
                height={20}
                src={icons.downArrowWhite}
              />
            </ScrollLink>
          </div>
        </div>
        <video autoPlay muted loop>
          <source src="/assets/background.mp4" type="video/mp4" />
        </video>
      </div>
      <Element name="home-second-page" className="home-second-page">
        <div className="home-second-page-inner-container">
          <div className="home-second-page-upper-section text-white">
            <div className="home-second-page-upper-title mb-24">
              At a glance
            </div>
            <div className="home-second-page-upper-content d-flex justify-content-between">
              <div className="home-second-page-upper-description fs-20 lh-28">
                <div className="mb-12">
                  <span className="fw-600">Looking Glass</span> has published
                  transparency records regarding:
                </div>
                <ul className="mb-12">
                  {Object.keys(NetworkType).map((e) => {
                    const networkTypeTyped = e as NetworkType;
                    return (
                      <li>
                        {complaintsObject?.complaintsCount[networkTypeTyped] ||
                          0}{' '}
                        {complaintsObject?.complaintsCount[networkTypeTyped] &&
                        complaintsObject.complaintsCount[networkTypeTyped] === 1
                          ? 'complaint'
                          : 'complaints'}{' '}
                        on{' '}
                        <span
                          className="link-design cursor-pointer"
                          onClick={() => navigate('/dashboard')}
                        >
                          {networkTypeTyped}
                        </span>{' '}
                        affecting{' '}
                        {cidsReviewedObject?.totalCidsReviewed[
                          networkTypeTyped
                        ] || 0}{' '}
                        {cidsReviewedObject?.totalCidsReviewed[
                          networkTypeTyped
                        ] &&
                        cidsReviewedObject.totalCidsReviewed[
                          networkTypeTyped
                        ] === 1
                          ? 'CID'
                          : 'CIDs'}
                      </li>
                    );
                  })}
                </ul>
                <div className="mb-12">
                  These complaints or requests originated from{' '}
                  <span className="fw-600">
                    {complainantsObject?.totalComplainants[
                      NetworkType.Filecoin
                    ] || 0}{' '}
                    {complainantsObject?.totalComplainants[
                      NetworkType.Filecoin
                    ] &&
                    complainantsObject?.totalComplainants[
                      NetworkType.Filecoin
                    ] === 1
                      ? 'complainant'
                      : 'complainants'}{' '}
                  </span>
                  in{' '}
                  <span className="fw-600">
                    {complainantsObject?.complainantCountries || 0}{' '}
                    {complainantsObject?.complainantCountries &&
                    complainantsObject?.complainantCountries === 1
                      ? 'country'
                      : 'countries'}
                  </span>
                  .{' '}
                  {
                    complaintsObject?.reviewedComplaintsCount[
                      NetworkType.Filecoin
                    ]
                  }{' '}
                  complaints have been assessed by{' '}
                  <span className="fw-600">
                    {assessorsObject?.totalAssessors[NetworkType.Filecoin] || 0}{' '}
                    {assessorsObject?.totalAssessors[NetworkType.Filecoin] &&
                    assessorsObject?.totalAssessors[NetworkType.Filecoin] === 1
                      ? 'assessor'
                      : 'assesors'}
                  </span>
                  .
                </div>
                <div>
                  Public records can be reviewed in detail{' '}
                  <span
                    className="link-design cursor-pointer"
                    onClick={() => navigate('/records')}
                  >
                    here
                  </span>
                  .
                </div>
              </div>
              <AdditionalResources />
            </div>
          </div>
          <div className="home-second-page-lower-section text-white d-flex">
            <div className="home-second-page-lower-right flex-grow-3  mr-24">
              <div className="home-second-page-lower-right-upper container-box mb-24 p-24">
                <div className="second-lower-right-upper-header d-flex justify-content-between mb-48">
                  <div className="second-lower-right-upper-header-title fs-20 lh-28 fw-700">
                    Complaints{' '}
                    <span className="light-grey-text fw-400">(total)</span>
                  </div>
                </div>
                <div className="second-lower-right-upper-content d-flex">
                  <div className="second-lower-right-upper-content-left d-flex flex-column flex-1">
                    <div className="second-lower-right-upper-content-left-upper d-flex align-items-center">
                      <div className="second-lower-right-upper-content-left-text flex-1">
                        {complaintsObject
                          ? complaintsObject.complaintsCount.totalCount
                          : 0}
                      </div>
                      <div className="second-lower-right-upper-content-left-statistics">
                        {!loadingStacked && complaintsPerDay && (
                          <div className="d-flex flex-column align-items-center">
                            <StackedLineGraph
                              name="complaints"
                              height={75}
                              width={160}
                              centerY={0}
                              data={complaintsPerDay.map((e) => ({
                                count: e.totalCount,
                                date: e.date,
                              }))}
                              onClickConfig={{
                                orderBy: 'submittedOn',
                                order: 'desc',
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="second-lower-right-upper-content-left-lower d-flex flex-column fs-20 lh-28">
                      <div className="second-lower-right-upper-content-left-received flex-1 mb-8">
                        {complaintsObject
                          ? complaintsObject?.submittedComplaintsCount
                              .totalCount
                          : 0}{' '}
                        <span className="fw-600">submitted</span>
                      </div>
                      <div className="second-lower-right-upper-content-left-assessed">
                        {complaintsObject
                          ? complaintsObject.reviewedComplaintsCount.totalCount
                          : 0}{' '}
                        <span className="fw-600">assessed</span>
                      </div>
                    </div>
                  </div>
                  <div className="second-lower-right-upper-content-right d-flex flex-15 justify-content-center align-items-center fs-36 fw-600">
                    {!loadingGeneral &&
                      complaintsObject &&
                      !!complaintsObject.complaintsCount.totalCount &&
                      !complaintsObject.complaintsTypes.totalCount.some((e) =>
                        isNaN(e.percentageCount)
                      ) &&
                      complaintsObject.complaintsTypes.totalCount &&
                      !!complaintsObject.complaintsTypes.totalCount.length && (
                        <PieChart
                          data={complaintsObject?.complaintsTypes.totalCount.map(
                            (e) => ({
                              ...e,
                              type: e.type,
                              count: e.percentageCount,
                            })
                          )}
                          name="home-pie"
                        />
                      )}
                  </div>
                </div>
              </div>
              <div className="home-second-page-lower-right-lower d-flex">
                <div className="home-second-page-lower-right-lower-left flex-1 mr-24 container-box small-container-box p-24 pb-0">
                  <div className="second-lower-right-upper-header d-flex justify-content-between mb-4px">
                    <div className="fs-20 lh-28 fw-700">
                      Complainants{' '}
                      <span className="light-grey-text fw-400">(total)</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="second-lower-right-upper-content-left-text flex-1">
                      {complainantsObject?.totalComplainants.totalCount}
                    </div>
                    <div className="second-lower-right-upper-content-left-statistics">
                      {!loadingStacked && complainantsPerDay && (
                        <div className="d-flex flex-column align-items-center">
                          <StackedLineGraph
                            name="complainants"
                            height={75}
                            width={160}
                            centerY={0}
                            data={complainantsPerDay.map((e) => ({
                              count: e.totalCount,
                              date: e.date,
                            }))}
                            onClickConfig={{
                              orderBy: 'fullName',
                              order: 'asc',
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="home-second-page-lower-right-lower-right flex-1 container-box small-container-box p-24 pb-0">
                  <div className="second-lower-right-upper-header d-flex justify-content-between mb-4px">
                    <div className="fs-20 lh-28 fw-700">
                      Assessors{' '}
                      <span className="light-grey-text fw-400">(total)</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="second-lower-right-upper-content-left-text flex-1">
                      {assessorsObject?.totalAssessors.totalCount}
                    </div>
                    <div className="second-lower-right-upper-content-left-statistics">
                      <div className="d-flex flex-column align-items-center">
                        <span
                          className="link-design cursor-pointer orange-text"
                          onClick={() => navigate('/assessors')}
                        >
                          See All -&gt;
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-second-page-lower-left flex-grow-1 container-box d-flex flex-column p-24">
              <div className="second-lower-left-header d-flex justify-content-between mb-48">
                <div className="second-lower-left-header-title fs-20 lh-28 fw-700">
                  CIDs reviewed{' '}
                  <span className="light-grey-text fw-400">(total)</span>
                </div>
              </div>
              <div className="second-lower-left-content d-flex flex-column">
                <div className="second-lower-left-content-first d-flex justify-content-between align-items-center mb-24">
                  <div className="second-lower-left-content-first-text flex-1">
                    {cidsReviewedObject?.totalCidsReviewed.totalCount}
                  </div>
                  {!loadingStacked && cidsReviewedPerDay && (
                    <div className="second-lower-left-content-first-statistics">
                      <div className="d-flex flex-column align-items-center">
                        <StackedLineGraph
                          name="cids-reviewed"
                          height={75}
                          width={160}
                          data={cidsReviewedPerDay.map((e) => ({
                            count: e.totalCount,
                            date: e.date,
                          }))}
                          onClickConfig={{
                            orderBy: 'submittedOn',
                            order: 'desc',
                          }}
                          centerY={0}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="second-lower-left-content-second d-flex flex-column">
                  <div className="second-lower-left-content-second-text mb-8">
                    {cidsReviewedObject?.acceptedCids.totalCount || 0}{' '}
                    <span className="fw-600">accepted</span>
                  </div>
                  <div className="second-lower-left-content-second-text">
                    {cidsReviewedObject?.rejectedCids.totalCount || 0}{' '}
                    <span className="fw-600">rejected</span>
                  </div>
                </div>
                <div className="second-lower-left-content-third d-flex flex-column justify-content-center align-items-center">
                  {!loadingGeneral && cidsReviewedObject && (
                    <SemiDonutChart
                      data={[
                        {
                          type: 'Filtered',
                          count:
                            cidsReviewedObject.filteredCidsPercentage
                              .totalCount,
                        },
                        {
                          type: 'Unfiltered',
                          count:
                            cidsReviewedObject.unfilteredCidsPercentage
                              .totalCount,
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
      <Footer />
    </div>
  );
};
