import dayjs from 'dayjs';
import { getSumByKey } from 'library/helpers/helper.functions';
import { NetworkType } from 'models/Common';
import {
  AssessorsData,
  CidsReviewedData,
  ComplainantsData,
  ComplaintType,
  ComplaintsData,
  CountriesData,
  FileTypesObject,
  GenericPerDayItem,
  IComplaintStats,
  TopFiveCountries,
  TypeStatsItemData,
} from 'models/Modules.models';
import { useEffect, useState } from 'react';
import countryList from 'react-select-country-list';
import ApiService from 'services/api.service';
import {
  GenericCountItem,
  GenericPerDayItemResponse,
  Region,
} from '../../models/GeneralStats';

export function useComplaintStats(
  timePeriod: number,
  regions?: Region[]
): IComplaintStats {
  const [cidsReviewedObject, setCidsReviewedObject] =
    useState<CidsReviewedData>();
  const [complaintsObject, setComplaintsObject] = useState<ComplaintsData>();
  const [complainantsObject, setComplainantsObject] =
    useState<ComplainantsData>();
  const [assessorsObject, setAssessorsObject] = useState<AssessorsData>();
  const [countriesObject, setCountriesObject] = useState<CountriesData>();
  const [fileTypesObject, setFileTypesObject] = useState<{
    [key in NetworkType]: FileTypesObject;
  }>();
  const [loadingGeneral, setLoadingGeneral] = useState<boolean>(true);

  const [cidsReviewedPerDay, setCidsReviewedPerDay] = useState<
    Array<GenericPerDayItemResponse>
  >([]);
  const [complaintsPerDay, setComplaintsPerDay] = useState<
    Array<GenericPerDayItemResponse>
  >([]);
  const [complainantsPerDay, setComplainantsPerDay] = useState<
    Array<GenericPerDayItemResponse>
  >([]);
  const [assessorsPerDay, setAssessorsPerDay] = useState<
    Array<GenericPerDayItemResponse>
  >([]);
  const [loadingStacked, setLoadingStacked] = useState<boolean>(true);

  const fetchGeneralStats = async (
    timePeriod: number,
    regions?: Region[]
  ): Promise<void> => {
    setLoadingGeneral(true);
    const filteringObject = {
      startDate: dayjs().subtract(timePeriod, 'day').format('YYYY-MM-DD'),
      regions: regions?.map((region: Region) => region.value),
    };
    const generalStatsApiData = await ApiService.fetchGeneralStats(
      filteringObject
    );
    const {
      type,
      fileType,
      country,
      acceptedInfringements,
      rejectedInfringements,
      filteredInfringements,
      unreviewedComplaintsCount,
      reviewedComplaintsCount,
      submittedComplaintsCount,
      complainant,
      assessor,
    } = generalStatsApiData;

    const acceptedCids = acceptedInfringements;
    const rejectedCids = rejectedInfringements;

    const totalCidsReviewed: GenericCountItem = {
      ...(Object.keys(NetworkType).reduce((acc, curr) => {
        const networkTypeTyped = curr as NetworkType;
        return {
          ...acc,
          [curr]:
            acceptedCids[networkTypeTyped] + rejectedCids[networkTypeTyped],
        };
      }, {}) as {
        [key in NetworkType]: number;
      }),
      totalCount: acceptedCids.totalCount + rejectedCids.totalCount,
    };

    const filteredCidsPercentage = {
      ...(Object.keys(NetworkType).reduce((acc, curr) => {
        const networkTypeTyped = curr as NetworkType;
        return {
          ...acc,
          [curr]:
            acceptedCids[networkTypeTyped] === 0
              ? 0
              : Math.round(
                  (filteredInfringements[networkTypeTyped] /
                    acceptedCids[networkTypeTyped]) *
                    100
                ),
        };
      }, {}) as {
        [key in NetworkType]: number;
      }),
      totalCount:
        acceptedCids.totalCount === 0
          ? 0
          : Math.round(
              (filteredInfringements.totalCount / acceptedCids.totalCount) * 100
            ),
    };

    const unfilteredCidsPercentage = {
      ...(Object.keys(NetworkType).reduce((acc, curr) => {
        const networkTypeTyped = curr as NetworkType;
        return {
          ...acc,
          [curr]: 100 - filteredCidsPercentage[networkTypeTyped],
        };
      }, {}) as {
        [key in NetworkType]: number;
      }),
      totalCount: 100 - filteredCidsPercentage.totalCount,
    };

    let avoidPercentageRoundingError = 0;
    const countriesWithoutFilter = country.filter((e) => e.scope !== 'Global');
    const totalCountriesCount = {
      ...(Object.keys(NetworkType).reduce((acc, curr) => {
        const networkTypeTyped = curr as NetworkType;
        return {
          ...acc,
          [curr]: getSumByKey(countriesWithoutFilter, networkTypeTyped),
        };
      }, {}) as {
        [key in NetworkType]: number;
      }),
      totalCount: getSumByKey(countriesWithoutFilter, 'totalCount'),
    };

    const topFiveCountries: TopFiveCountries = {};
    const complaintsTypes: {
      [key in NetworkType]: Array<TypeStatsItemData>;
    } & {
      totalCount: Array<TypeStatsItemData>;
    } = {
      [NetworkType.Filecoin]: [],
      [NetworkType.IPFS]: [],
      totalCount: [],
    };
    const fileTypesObject: {
      [key in NetworkType]: FileTypesObject;
    } & {
      totalCount: FileTypesObject;
    } = {
      [NetworkType.Filecoin]: [],
      [NetworkType.IPFS]: [],
      totalCount: [],
    };

    [...Object.keys(NetworkType), 'totalCount'].forEach((networkType) => {
      avoidPercentageRoundingError = 0;
      const networkTypeCasted = networkType as NetworkType | 'totalCount';
      topFiveCountries[networkTypeCasted] = countriesWithoutFilter
        .map((e, index) => {
          let percentageCount = 0;
          if (index !== countriesWithoutFilter.length - 1) {
            percentageCount = Math.round(
              (e[networkTypeCasted] / totalCountriesCount[networkTypeCasted]) *
                100
            );
            avoidPercentageRoundingError =
              avoidPercentageRoundingError + percentageCount;
          } else {
            percentageCount = 100 - avoidPercentageRoundingError;
          }

          return {
            count: e[networkTypeCasted],
            percentageCount,
            label:
              countryList()
                .getData()
                .find((el) => el.value === e.scope)?.label || '-',
            id: e.scope,
          };
        })
        .sort((a, b) => b.count - a.count)
        .slice(0, 5);

      avoidPercentageRoundingError = 0;

      complaintsTypes[networkTypeCasted] = type.map((e, index) => {
        const r: {
          count: number;
          percentageCount: number;
          type: ComplaintType;
        } = {
          count: e[networkTypeCasted],
          percentageCount: 0,
          type: ComplaintType.Copyright,
        };
        if (index !== type.length - 1) {
          r.percentageCount = Math.round(
            (r.count / complaintsCount[networkTypeCasted]) * 100
          );
          avoidPercentageRoundingError =
            avoidPercentageRoundingError + r.percentageCount;
        } else {
          r.percentageCount = 100 - avoidPercentageRoundingError;
        }
        if (e.type === 1) {
          r.type = ComplaintType.Copyright;
        }
        if (e.type === 2) {
          r.type = ComplaintType.Inappropriate;
        }
        if (e.type === 3) {
          r.type = ComplaintType.Other;
        }
        return r;
      });

      avoidPercentageRoundingError = 0;
      fileTypesObject[networkTypeCasted] = fileType.map((e, index) => {
        const fileTypeObject = {
          count: e[networkTypeCasted],
          percentageCount: 0,
          fileType: e.fileType,
        };

        if (index !== fileType.length - 1) {
          fileTypeObject.percentageCount = Math.round(
            (e[networkTypeCasted] / totalCidsReviewed[networkTypeCasted]) * 100
          );
          avoidPercentageRoundingError =
            avoidPercentageRoundingError + fileTypeObject.percentageCount;
        } else {
          fileTypeObject.percentageCount = 100 - avoidPercentageRoundingError;
        }

        return fileTypeObject;
      });
    });

    setCidsReviewedObject({
      acceptedCids,
      rejectedCids,
      totalCidsReviewed,
      filteredCidsPercentage,
      unfilteredCidsPercentage,
    });
    const complaintsCount = {
      ...(Object.keys(NetworkType).reduce((acc, curr) => {
        const networkTypeTyped = curr as NetworkType;
        return {
          ...acc,
          [curr]:
            submittedComplaintsCount[networkTypeTyped] +
            reviewedComplaintsCount[networkTypeTyped] +
            unreviewedComplaintsCount[networkTypeTyped],
        };
      }, {}) as {
        [key in NetworkType]: number;
      }),
      totalCount:
        submittedComplaintsCount.totalCount +
        reviewedComplaintsCount.totalCount +
        unreviewedComplaintsCount.totalCount,
    };
    avoidPercentageRoundingError = 0;

    setComplaintsObject({
      submittedComplaintsCount,
      reviewedComplaintsCount,
      complaintsCount,
      complaintsTypes,
    });

    setComplainantsObject({
      totalComplainants: complainant,
      complainantCountries: country?.length || 0,
    });

    setAssessorsObject({
      totalAssessors: assessor,
    });

    setCountriesObject({
      totalCountries: country.length,
      topFiveCountries: topFiveCountries,
    });

    avoidPercentageRoundingError = 0;
    setFileTypesObject(fileTypesObject);
    setLoadingGeneral(false);
  };

  const fetchStackedLineData = async (
    timePeriod: number,
    regions?: Region[]
  ) => {
    setLoadingStacked(true);
    const filteringObject = {
      startDate: dayjs().subtract(timePeriod, 'day').format('YYYY-MM-DD'),
      regions: regions?.map((region: Region) => region.value),
    };
    const _cidsReviewedPerDay = await ApiService.cidsReviewedPerDay(
      filteringObject
    );
    const _complaintsPerDay = await ApiService.complaintsPerDay(
      filteringObject
    );
    const _complainantsPerDay = await ApiService.complainantsPerDay(
      filteringObject
    );
    // used in the past, will keep if needed in the future
    // const _assessorsPerDay = await ApiService.assessorsPerDay(filteringObject);
    setCidsReviewedPerDay(_cidsReviewedPerDay);
    setComplaintsPerDay(_complaintsPerDay);
    // used in the past, will keep if needed in the future
    // setAssessorsPerDay(
    //   _assessorsPerDay.map((e) => ({
    //     ...e,
    //     count: e.count,
    //   }))
    // );
    setComplainantsPerDay(_complainantsPerDay);
    setLoadingStacked(false);
  };

  useEffect(() => {
    fetchGeneralStats(timePeriod, regions);
    fetchStackedLineData(timePeriod, regions);
  }, [regions, timePeriod]);

  return {
    //General Stats Data
    cidsReviewedObject,
    complaintsObject,
    complainantsObject,
    assessorsObject,
    countriesObject,
    fileTypesObject: fileTypesObject,
    loadingGeneral,

    //Stacked Line Data
    cidsReviewedPerDay,
    complaintsPerDay,
    complainantsPerDay,
    assessorsPerDay,
    loadingStacked,
  };
}
