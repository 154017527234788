import { useLocation, useNavigate } from 'react-router-dom';
import './Header.css';
import { HeaderAppSwitcher } from './HeaderAppSwitcher';

export const Header = (props: any): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div
      className={`header-container d-flex px-48 align-items-center justify-content-between
    
    `}
    >
      <div className="header-left-section h-100 d-flex align-items-center">
        <div
          onClick={() => navigate('/')}
          className="header-logo fs-28 lh-36 fw-700 text-white mr-48 no-text-select d-flex align-items-center cursor-pointer"
        >
          Looking Glass
        </div>
        <div
          onClick={() => navigate('/dashboard')}
          className={`header-dashboard-button fs-14 lh-20 fw-600 text-white mr-24 c-pointer no-text-select h-100 d-flex align-items-center px-1          
          ${
            pathname.includes('/dashboard') && !pathname.includes('complaints')
              ? 'active-link'
              : ''
          }
          `}
        >
          Dashboard
        </div>
        <div
          onClick={() => navigate('/records')}
          className={`header-records-button fs-14 lh-20 fw-600 text-white mr-24 c-pointer no-text-select h-100 d-flex align-items-center px-1
          ${pathname.includes('/records') ? 'active-link' : ''}
          `}
        >
          Records
        </div>
        <div
          onClick={() => navigate('/assessors')}
          className={`header-records-button fs-14 lh-20 fw-600 text-white mr-24 c-pointer no-text-select h-100 d-flex align-items-center px-1
          ${pathname.includes('/assessors') ? 'active-link' : ''}
          `}
        >
          Assessors
        </div>
      </div>
      <div className="header-right-section d-flex align-items-center">
        <div
          onClick={() => navigate('/complaint')}
          className={
            'mr-20 header-records-button fs-14 lh-20 fw-600 text-white c-pointer px-20 btn btn-primary'
          }
        >
          File a Complaint
        </div>
        <HeaderAppSwitcher />
      </div>
    </div>
  );
};
