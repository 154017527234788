import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import MUITooltip from '@mui/material/Tooltip';
import CIDStatusBadge from 'common/CIDStatusBadge';
import { SemiDonutChart } from 'common/Charts/SemiDonut';
import { WorldMap } from 'common/Charts/WorldMap';
import dayjs from 'dayjs';
import { parseFilteringStatusToString } from 'library/helpers/helper.functions';
import { Filter, PublicRelatedComplaints } from 'models/IndividualRecord';
import { ComplaintType } from 'models/Modules.models';
import {
  AdjustedFilterListsComplaint,
  ComplaintTypeInt,
  HostedBy,
  HostedByArrayItem,
  NodeFilteringStatus,
  Visibility,
} from 'models/PublicComplaints';
import { useEffect, useRef, useState } from 'react';
import { Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as icons from 'resources/icons';
import { whiteExclamationMark } from 'resources/icons';
import ApiService from 'services/api.service';
import { bitscreenUri, rodeoUri } from '../../../config';
import { RecordsPropsState } from '../TableRecords/TableRecords';
import './IndividualRecord.css';
import { NetworkChips } from 'common/NetworkChips';

const redactedChar = '*';

export const IndividualRecord = (props: any): JSX.Element => {
  const query = useRef('');
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const recordsFilter = location.state
    ? (location.state as RecordsPropsState).recordsFilter
    : undefined;
  const recordsIdsArray = location.state
    ? (location.state as RecordsPropsState).recordsIdsArray
    : undefined;
  const [publicComplaint, setPublicComplaint] =
    useState<AdjustedFilterListsComplaint>();
  const [allDescriptionAreas, setAllDescriptionAreas] = useState<
    Array<{
      redacted: boolean;
      rangeStart: number;
      rangeEnd: number;
    }>
  >([]);
  const [publicRelatedComplaints, setPublicRelatedComplaints] =
    useState<PublicRelatedComplaints>({
      complaintsByCids: [],
      complaintsByComplainant: [],
    });
  const [publicRelatedFilters, setPublicRelatedFilters] =
    useState<Array<Filter>>();

  const [affectedCIDsShown, setAffectedCIDsShown] = useState(5);

  const fetchPublicRecord = async (routeId?: string) => {
    const id = routeId || params.id || '1';
    const publicRecordResponse = await ApiService.getPublicComplaint(id);
    const publicRelatedComplaints = await ApiService.getPublicRelatedComplaints(
      id
    );
    const publicRelatedFilters = await ApiService.getRelatedFilters(id);

    publicRelatedComplaints.complaintsByComplainant.forEach((e) => {
      switch (e.type) {
        case ComplaintTypeInt.Copyright:
          e.typeString = ComplaintType.Copyright;
          break;

        case ComplaintTypeInt.Inappropriate:
          e.typeString = ComplaintType.Inappropriate;
          break;

        case ComplaintTypeInt.Other:
          e.typeString = ComplaintType.Other;
      }
    });

    publicRelatedComplaints.complaintsByCids.forEach((e) => {
      e.complaints.forEach((el) => {
        switch (el.type) {
          case ComplaintTypeInt.Copyright:
            el.typeString = ComplaintType.Copyright;
            break;

          case ComplaintTypeInt.Inappropriate:
            el.typeString = ComplaintType.Inappropriate;
            break;

          case ComplaintTypeInt.Other:
            el.typeString = ComplaintType.Other;
        }
      });
    });

    let nodesFiltering = 0;
    let totalNodes = 0;
    const countryList: Array<string> = [];

    const hostedByArray: Array<HostedByArrayItem> = [];
    publicRecordResponse.infringements.forEach((e) => {
      if (e.hostedBy) {
        e.hostedBy.forEach((el) => {
          totalNodes++;
          if (el.country) countryList.push(el.country);
          if (el.filtering === NodeFilteringStatus.Filtering) nodesFiltering++;
          hostedByArray.push({
            ...el,
          });
        });
      }
    });

    const filteredPercentage = parseFloat(
      ((nodesFiltering / totalNodes) * 100).toFixed(2)
    );

    const hostedBy: HostedBy = {
      items: hostedByArray,
      filteredPercentage,
      unfilteredPercentage: 100 - filteredPercentage,
      countryList,
    };

    setPublicComplaint({
      ...publicRecordResponse,
      hostedBy,
    });
    setPublicRelatedComplaints(publicRelatedComplaints);
    setPublicRelatedFilters(publicRelatedFilters);
  };

  useEffect(() => {
    fetchPublicRecord();
    document.title = `Looking Glass - Complaint ${publicComplaint?.title}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    parseRedactedDescription();
  }, [publicComplaint]);

  const parseRedactedDescription = () => {
    if (!publicComplaint || !publicComplaint.redactedComplaintDescription) {
      return setAllDescriptionAreas([]);
    } else if (!publicComplaint.redactionReason) {
      return setAllDescriptionAreas([
        {
          rangeStart: 0,
          rangeEnd: publicComplaint.redactedComplaintDescription.length,
          redacted: false,
        },
      ]);
    }

    const { redactedComplaintDescription } = publicComplaint;
    const splitRedactedComplaintDescription =
      redactedComplaintDescription.split('');
    const redactedAreas: Array<{
      rangeStart: number;
      rangeEnd: number;
    }> = [];

    for (let i = 0; i < splitRedactedComplaintDescription.length; i++) {
      if (splitRedactedComplaintDescription[i] === redactedChar) {
        redactedAreas.push({
          rangeStart: i,
          rangeEnd: i + 1,
        });
      }
    }

    const filteredRedactedAreas = [...redactedAreas];

    for (let i = 0; i < redactedAreas.length; i++) {
      if (
        (i === 0 &&
          redactedAreas[i].rangeStart === 0 &&
          (!redactedAreas[i + 1] || redactedAreas[i + 1].rangeStart !== 1)) ||
        (i === redactedAreas.length - 1 &&
          redactedAreas[i].rangeStart ===
            splitRedactedComplaintDescription.length &&
          (!redactedAreas[i - 1] ||
            redactedAreas[i - 1].rangeStart !==
              splitRedactedComplaintDescription.length - 1)) ||
        (i !== 0 &&
          i !== redactedAreas.length - 1 &&
          (redactedAreas[i].rangeStart !== redactedAreas[i - 1].rangeEnd ||
            redactedAreas[i].rangeEnd !== redactedAreas[i + 1].rangeStart))
      ) {
        filteredRedactedAreas.splice(i, 1);
      }
    }

    const mashedRedactedAreas: typeof filteredRedactedAreas = [];

    for (let i = 0; i < filteredRedactedAreas.length; i++) {
      if (i === 0) {
        mashedRedactedAreas.push(filteredRedactedAreas[i]);
        continue;
      }

      if (
        filteredRedactedAreas[i].rangeStart ===
        filteredRedactedAreas[i - 1].rangeEnd
      ) {
        mashedRedactedAreas[mashedRedactedAreas.length - 1].rangeEnd =
          filteredRedactedAreas[i].rangeEnd;
      } else {
        mashedRedactedAreas.push(filteredRedactedAreas[i]);
      }
    }

    const allAreas: Array<{
      redacted: boolean;
      rangeStart: number;
      rangeEnd: number;
    }> = [];

    for (let i = 0; i < mashedRedactedAreas.length; i++) {
      if (i === 0 && mashedRedactedAreas[i].rangeStart > 0) {
        allAreas.push({
          redacted: false,
          rangeStart: 0,
          rangeEnd: mashedRedactedAreas[i].rangeStart,
        });
      }

      if (
        0 < i &&
        mashedRedactedAreas[i].rangeStart > mashedRedactedAreas[i - 1].rangeEnd
      ) {
        allAreas.push({
          redacted: false,
          rangeStart: mashedRedactedAreas[i - 1].rangeEnd,
          rangeEnd: mashedRedactedAreas[i].rangeStart,
        });
      }

      if (
        i === mashedRedactedAreas.length - 1 &&
        mashedRedactedAreas[i].rangeEnd <
          publicComplaint.redactedComplaintDescription.length
      ) {
        allAreas.push({
          redacted: false,
          rangeStart: mashedRedactedAreas[i].rangeEnd,
          rangeEnd: publicComplaint.redactedComplaintDescription.length,
        });
      }

      allAreas.push({
        redacted: true,
        rangeStart: mashedRedactedAreas[i].rangeStart,
        rangeEnd: mashedRedactedAreas[i].rangeEnd,
      });
    }

    allAreas.sort((a, b) => a.rangeStart - b.rangeStart);

    setAllDescriptionAreas(allAreas);
  };

  const displayRedactedComplaint = () => {
    return allDescriptionAreas.map((e, index) =>
      e.redacted ? (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip className="redaction-reason-tooltip">
              Redaction reason: {publicComplaint!.redactionReason}
            </Tooltip>
          }
        >
          <span
            key={`redacted-span-${index}`}
            className="redacted-span no-text-select"
          >
            {publicComplaint!.redactedComplaintDescription.substring(
              e.rangeStart,
              e.rangeEnd
            )}
          </span>
        </OverlayTrigger>
      ) : (
        <span key={`redacted-span-${index}`}>
          {publicComplaint!.redactedComplaintDescription.substring(
            e.rangeStart,
            e.rangeEnd
          )}
        </span>
      )
    );
  };

  const currentIndexInIdsArray = recordsIdsArray
    ? recordsIdsArray.findIndex((e) => params.id === e)
    : 0;

  return (
    <div className="individual-record-container text-white">
      <div className="individual-record-header justify-between px-48 py-16">
        <div className="individual-record-header-input">
          <InputGroup className="individual-record-search-input-group">
            <InputGroup.Text
              className="individual-record-search-icon-container pt-8 pb-8 pl-12 pr-10"
              id="search"
            >
              <img alt="search-icon" src={icons.search} />
            </InputGroup.Text>
            <Form.Control
              className="individual-record-search-input pl-0 no-outline no-box-shadow"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search"
              onChange={(e) => {
                query.current = e.target.value;
              }}
              onKeyDown={(e) => {
                if (e.code !== 'Enter') return;
                navigate('/records', {
                  replace: true,
                  state: {
                    query: query.current,
                  },
                });
              }}
            />
            {/* <InputGroup.Text
                  className="remove-icon-container pt-8 pb-8 pl-12 pr-10"
                  id="search"
                  >
                  {searchQuery && (
                    <img
                    onClick={() => {
                      if (searchQuery) setSearchQuery('');
                    }}
                    className="remove-icon c-pointer no-text-select"
                    src={icons.removeList}
                    />
                    )}
                  </InputGroup.Text> */}
          </InputGroup>
        </div>
        <div className="individual-record-header-buttons d-flex align-items-center h-100">
          <div
            onClick={() => {
              navigate('/records', {
                replace: true,
                state: {
                  ...recordsFilter,
                },
              });
            }}
            className="individual-record-header-back generic-button transparent-button mr-12"
          >
            Back to results
          </div>
          <div
            onClick={() => {
              if (!recordsIdsArray) return;

              if (currentIndexInIdsArray === 0) return;
              const newId = recordsIdsArray[currentIndexInIdsArray - 1];

              navigate(`/records/${newId}`, {
                replace: true,
                state: {
                  recordsIdsArray,
                  recordsFilter,
                },
              });
              fetchPublicRecord(newId);
            }}
            className={`individual-record-header-next-record generic-button transparent-button mr-12 ${
              currentIndexInIdsArray === 0 ? 'disabled-button-styling' : ''
            }`}
          >
            Previous record
          </div>
          <div
            onClick={() => {
              if (!recordsIdsArray) return;

              if (currentIndexInIdsArray === recordsIdsArray.length - 1) return;
              const newId = recordsIdsArray[currentIndexInIdsArray + 1];
              navigate(`/records/${newId}`, {
                replace: true,
                state: {
                  recordsIdsArray,
                  recordsFilter,
                },
              });
              fetchPublicRecord(newId);
            }}
            className={`individual-record-header-next-record generic-button transparent-button ${
              recordsIdsArray &&
              recordsIdsArray.length - 1 === currentIndexInIdsArray
                ? 'disabled-button-styling'
                : ''
            }`}
          >
            Next record
          </div>
        </div>
      </div>
      <div className="individual-record-first-section d-flex px-48 py-48">
        <div className="individual-record-first-section-left d-flex flex-column flex-1">
          {publicComplaint && (
            <div className="individual-record-first-section-left-upper mb-48">
              <div className="individual-record-first-section-left-upper-title mb-24">
                {publicComplaint.title || 'Complaint'}
              </div>
              <div className="individual-record-first-section-left-upper-description fs-18 lh-24">
                {displayRedactedComplaint()}
              </div>
            </div>
          )}
          <div className="individual-record-first-section-left-middle mb-48">
            <div className="individual-record-first-section-left-middle-title fs-28 lh-36 fw-700 mb-24">
              Timeline
            </div>
            <div className="individual-record-first-section-left-middle-list fs-18 lh-24">
              <Timeline>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: '#444D56' }} />
                    <TimelineConnector
                      style={{
                        backgroundColor: '#444D56',
                        marginTop: '-11.5px',
                        marginBottom: '-11.5px',
                      }}
                    />
                  </TimelineSeparator>
                  {publicComplaint && (
                    <TimelineContent>
                      Complaint submitted on{' '}
                      {dayjs(publicComplaint.created).format('YYYY-MM-DD')} by{' '}
                      {publicComplaint.fullName}.
                    </TimelineContent>
                  )}
                </TimelineItem>
                {publicComplaint &&
                  publicComplaint.adjustedFilterLists &&
                  publicComplaint.adjustedFilterLists.length > 0 && (
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot style={{ backgroundColor: '#444D56' }} />
                        <TimelineConnector
                          style={{
                            backgroundColor: '#444D56',
                            marginTop: '-11.5px',
                            marginBottom: '-11.5px',
                          }}
                        />
                      </TimelineSeparator>
                      <TimelineContent>
                        CID(s) added to {` `}
                        {publicComplaint.adjustedFilterLists.map((e, index) => {
                          if (
                            index !==
                            publicComplaint.adjustedFilterLists.length - 1
                          )
                            return (
                              <span>
                                {e.visibility === Visibility.Private
                                  ? 'private list'
                                  : e.name}
                                ,{' '}
                              </span>
                            );
                          else
                            return (
                              <span>
                                {e.visibility === Visibility.Private
                                  ? 'private list'
                                  : e.name}
                              </span>
                            );
                        })}{' '}
                        by {publicComplaint.assessor.businessName}.
                      </TimelineContent>
                    </TimelineItem>
                  )}
                {publicComplaint && (
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot style={{ backgroundColor: '#444D56' }} />
                      <TimelineConnector
                        style={{
                          backgroundColor: '#444D56',
                          marginTop: '-11.5px',
                          marginBottom: '-11.5px',
                        }}
                      />
                    </TimelineSeparator>
                    <TimelineContent>
                      CID(s) accepted:{' '}
                      {
                        publicComplaint.infringements.filter((e) => e.accepted)
                          .length
                      }
                      ; CID(s) rejected:{' '}
                      {
                        publicComplaint.infringements.filter((e) => !e.accepted)
                          .length
                      }
                    </TimelineContent>
                  </TimelineItem>
                )}
                {publicComplaint && (
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot style={{ backgroundColor: '#444D56' }} />
                    </TimelineSeparator>
                    {publicComplaint.isSpam ? (
                      <TimelineContent>
                        Complaint marked as spam by {` `}
                        {publicComplaint.assessor.businessName} on {` `}
                        {dayjs(publicComplaint.resolvedOn).format('YYYY-MM-DD')}
                      </TimelineContent>
                    ) : (
                      <TimelineContent>
                        Public records published on{' '}
                        {dayjs(
                          publicComplaint.submittedOn
                            ? publicComplaint?.submittedOn
                            : publicComplaint?.resolvedOn
                        ).format('YYYY-MM-DD')}{' '}
                        by {publicComplaint.assessor.businessName}.
                      </TimelineContent>
                    )}
                  </TimelineItem>
                )}
              </Timeline>
              {/* <ul className="mb-0">
                <li className="mb-12">
                  Complaint submitted on{' '}
                  {dayjs(publicComplaint?.created).format('YYYY-MM-DD')} by{' '}
                  {publicComplaint?.fullName}.
                </li>
                <li className="mb-12">
                  CID(s) added to [Filter name] on [YYYY/MM/DD] by{' '}
                  {publicComplaint?.assessor.contactPerson}.
                </li>
                <li>
                  Public records published on{' '}
                  {dayjs(publicComplaint?.submittedOn).format('YYYY-MM-DD')} by{' '}
                  {publicComplaint?.assessor.contactPerson}.
                </li>
              </ul> */}
            </div>
          </div>
          <div className="individual-record-first-section-left-lower">
            <div className="individual-record-first-section-left-lower-title fs-28 lh-36 fw-700 mb-24">
              Affected CID(s)
            </div>
            <div className="individual-record-first-section-left-lower-list d-flex flex-column fs-18 lh-24">
              {publicComplaint?.infringements
                .slice(0, affectedCIDsShown)
                .map((e) => (
                  <div className="individual-record-first-section-left-lower-list-item d-flex align-items-center mb-12">
                    {e.value} <CIDStatusBadge accepted={e.accepted} />
                  </div>
                ))}
              {publicComplaint &&
                publicComplaint.infringements.length > affectedCIDsShown && (
                  <div
                    onClick={() => setAffectedCIDsShown(affectedCIDsShown + 5)}
                    className="link-design fs-14 lh-20 c-pointer no-text-select"
                  >
                    Show more
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="individual-record-first-section-right d-flex flex-column">
          <div className="individual-record-first-section-right-id fs-28 lh-36 fw-700 mb-48">
            ID {publicComplaint?._id}
            {publicComplaint && publicComplaint.isSpam && (
              <MUITooltip
                arrow
                title={`Marked as spam by ${
                  publicComplaint.assessor.businessName
                } on ${dayjs(publicComplaint.resolvedOn).format(
                  'YYYY-MM-DD'
                )}.`}
              >
                <div className="marked-as-spam-badge">
                  <img alt="attention symbol" src={whiteExclamationMark} />
                  Marked as spam
                </div>
              </MUITooltip>
            )}
          </div>
          <div className="individual-record-first-section-right-reporter d-flex flex-column mb-48">
            <div className="individual-record-first-section-right-title fs-20 lh-28 fw-700 mb-24">
              Scope
            </div>
            <div className="individual-record-first-section-right-list fs-16 lh-24 fw-400 d-flex flex-column ">
              <div className="individual-record-first-section-right-list-item">
                {publicComplaint?.geoScope.map((e, index) => {
                  if (index !== publicComplaint.geoScope.length - 1)
                    return <span>{e}, </span>;
                  else return <span>{e}</span>;
                })}
              </div>
            </div>
          </div>
          <div className="individual-record-first-section-right-reporter d-flex flex-column mb-48">
            <div className="individual-record-first-section-right-title fs-20 lh-28 fw-700 mb-24">
              Complainant
            </div>
            <div className="individual-record-first-section-right-list fs-16 lh-24 fw-400 d-flex flex-column mb-24">
              <div className="individual-record-first-section-right-list-item mb-12">
                {publicComplaint?.fullName}
              </div>
              {publicComplaint?.companyName && (
                <div className="individual-record-first-section-right-list-item">
                  {publicComplaint?.companyName}
                </div>
              )}
            </div>
            <div
              onClick={() => {
                navigate('/records', {
                  replace: true,
                  state: {
                    complainantEmail: publicComplaint?.email,
                  },
                });
              }}
              className="individual-record-first-section-right-view-all link-design fs-14 lh-20 c-pointer no-text-select"
            >
              View all by complainant
            </div>
          </div>
          <div className="individual-record-first-section-right-assessor d-flex flex-column mb-48">
            <div className="individual-record-first-section-right-title fs-20 lh-28 fw-700 mb-24">
              Assessed by
            </div>
            <div className="individual-record-first-section-right-list d-flex flex-column mb-24">
              <div className="individual-record-first-section-right-list-item mb-12">
                {publicComplaint?.assessor.businessName}
              </div>
              <div className="individual-record-first-section-right-list-item mb-12">
                {publicComplaint?.assessor.website}
              </div>
              <div className="individual-record-first-section-right-list-item">
                {publicComplaint?.assessor.country}
              </div>
              <div className="individual-record-first-section-right-list-item">
                Registered on{' '}
                {dayjs(publicComplaint?.assessor.created).format('YYYY-MM-DD')}
              </div>
            </div>
            <div
              onClick={() => {
                navigate(`/assessors/${publicComplaint?.assessor.id}`, {
                  replace: true,
                  state: {
                    assessorId: publicComplaint?.assessor.id,
                  },
                });
              }}
              className="individual-record-first-section-right-view-all link-design fs-14 lh-20 c-pointer no-text-select"
            >
              View all by assessor
            </div>
          </div>
          {!!allDescriptionAreas.length &&
            allDescriptionAreas.find((e) => e.redacted) && (
              <div className="individual-record-first-section-right-assessor d-flex flex-column">
                <div className="individual-record-first-section-right-title fs-20 lh-28 fw-700 mb-24">
                  Redaction reason
                </div>
                <div className="individual-record-first-section-right-list d-flex flex-column mb-24">
                  <div className="individual-record-first-section-right-list-item mb-12">
                    {publicComplaint?.redactionReason}
                  </div>
                </div>
              </div>
            )}
          <div className="individual-record-first-section-right-assessor d-flex flex-column">
            <div className="individual-record-first-section-right-title fs-20 lh-28 fw-700 mb-24">
              Networks
            </div>
            {publicComplaint && (
              <NetworkChips networks={publicComplaint.networks} />
            )}
          </div>
        </div>
      </div>
      <div className="individual-record-second-section d-flex flex-column pt-48 pb-36">
        <div className="individual-record-second-section-upper px-48 mb-48">
          <div className="individual-record-second-section-upper-title mb-24 fs-28 lh-36 fw-700">
            Related
          </div>
          <div className="individual-record-second-section-upper-description fs-18 lh-24">
            Below are data related to this complaint, including other complaints
            against the affected CIDs, any public lists filtering these CIDs in
            BitScreen, and deal data from FilFox.
          </div>
        </div>
        <div className="individual-record-second-section-lower d-flex px-24">
          <div className="individual-record-second-section-lower-complaints container-box p-24 flex-1 mr-24">
            <div className="mb-24 fs-20 lh-28 fw-700">Complaints</div>
            <div className="mb-24">
              Other complaints submitted by any complainant or assessor against
              affected CIDs.
            </div>
            {[
              ...publicRelatedComplaints.complaintsByComplainant,
              ...publicRelatedComplaints.complaintsByCids
                .map((e) => e.complaints)
                .flat(1),
            ]
              .slice(0, 5)
              .map((e) => {
                return (
                  <div
                    onClick={() =>
                      window.open(`${rodeoUri()}complaint/${e._id}`, '_blank')
                    }
                    className="mb-12 cursor-pointer link-design"
                  >{`${e.typeString} (${e._id})`}</div>
                );
              })}
          </div>
          <div className="individual-record-second-section-lower-bitscreen container-box p-24 flex-1 mr-24">
            <div className="mb-24 fs-20 lh-28 fw-700">BitScreen filters</div>
            <div className="mb-24">
              Other filters in the public directory that include affected
              CID(s).
            </div>
            <ul>
              {publicRelatedFilters &&
                !!publicRelatedFilters.length &&
                publicRelatedFilters.slice(0, 5).map((e) => (
                  <li className="mb-12">
                    <span
                      onClick={() =>
                        window.open(
                          `${bitscreenUri()}/directory/details/${e.shareId}`,
                          '_blank'
                        )
                      }
                      className="link-design c-pointer fs-14 lh-20 fw-500"
                    >
                      {e.name}
                    </span>
                  </li>
                ))}
            </ul>
          </div>
          <div className="individual-record-second-section-lower-filfox container-box p-24 flex-1">
            <div className="mb-24 fs-20 lh-28 fw-700">FilFox deals</div>
            <div className="mb-24">
              FilFox deals related to the affected CID(s).
            </div>
            {publicComplaint &&
            publicComplaint.hostedBy &&
            publicComplaint.hostedBy.items &&
            publicComplaint.hostedBy.items.length ? (
              publicComplaint.hostedBy.items.map((e) => (
                <div className="mb-12">
                  <span
                    onClick={() => {
                      window.open(
                        `https://filfox.info/en/deal/${e.dealId}`,
                        '_blank'
                      );
                    }}
                    className="resources-link cursor-pointer no-text-select"
                  >
                    {e.dealId}
                  </span>
                </div>
              ))
            ) : (
              <li>None</li>
            )}
          </div>
        </div>
      </div>
      <div className="individual-record-third-section d-flex flex-column py-48">
        <div className="individual-record-third-section-upper px-48 mb-48">
          <div className="individual-record-third-section-upper-title mb-24 fs-28 lh-36 fw-700">
            Nodes
          </div>
          <div className="individual-record-third-section-upper-description fs-18 lh-24">
            Indicated below are nodes which are hosting this content, their
            geographic distribution, and their present filtering status for
            affected CIDs.
          </div>
        </div>
        <div className="individual-record-third-section-lower px-24 d-flex">
          <div className="individual-record-third-section-lower-nodes container-box p-24 flex-1 mr-24">
            <div className="mb-24 fs-20 lh-28 fw-700">Affected nodes</div>
            <div className="mb-24">
              List of all nodes hosting the affected CID(s) and their filtering
              status.
            </div>
            <div className="individual-record-third-section-lower-nodes-content d-flex">
              <div className="individual-record-third-section-lower-nodes-left flex-1">
                {publicComplaint && !!publicComplaint.hostedBy.items.length ? (
                  publicComplaint.hostedBy.items.map((e) => (
                    <div className="mb-12">
                      [{e.node}] ({parseFilteringStatusToString(e.filtering)})
                    </div>
                  ))
                ) : (
                  <li>None</li>
                )}
              </div>
              <div className="individual-record-third-section-lower-nodes-right flex-1 d-flex flex-column align-items-center">
                {publicComplaint && !!publicComplaint.hostedBy.items.length && (
                  <SemiDonutChart
                    data={[
                      {
                        type: 'Filtered',
                        count: publicComplaint.hostedBy.filteredPercentage,
                      },
                      {
                        type: 'Unfiltered',
                        count: publicComplaint.hostedBy.unfilteredPercentage,
                      },
                    ]}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="individual-record-third-section-lower-nodes container-box p-24 flex-2 d-flex flex-column">
            <div className="mb-24 fs-20 lh-28 fw-700">
              Geographic distribution of hosting nodes
            </div>
            <div className="dashboard-content-third-map flex-1 d-flex justify-content-center align-items-center fs-34 fw-700">
              {publicComplaint &&
                !!publicComplaint.hostedBy.countryList.length && (
                  <WorldMap
                    data={publicComplaint.hostedBy.countryList.map((e) => ({
                      id: e,
                    }))}
                    color="F66A0A"
                    name="individual-record-world-map"
                  ></WorldMap>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
