import { Container, Col, Row, Button } from 'react-bootstrap';

import '../Complaint.css';
import './ThankYou.css';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

export const ThankYou = (props: any): JSX.Element => {
  const navigate = useNavigate();

  return (
    <>
      <Container className="complaint-container">
        <Row>
          <Col>
            <Row>
              <Col className="main-container" md="auto">
                <Row className="icon-row">
                  <CheckCircleFill color="#0D6EFD" size={76} />
                </Row>
                <Row className="title-row">
                  <p aria-label="thank-you-page-title">Thank you!</p>
                </Row>
                <Row className="subtitle-row">
                  <h4 aria-label="thank-you-page-subtitle">
                    Your report has been submitted and will be reviewed as soon
                    as possible.
                  </h4>
                </Row>
                <Row
                  aria-label="thank-you-page-description"
                  className="description-row"
                >
                  Upon review of the report, you will receive a copy of our
                  assessment and a link to the public transparency record.
                </Row>
                <Row className="final-row">
                  <Col>
                    <Button
                      variant="primary"
                      onClick={() => navigate('/complaint')}
                      aria-label="thank-you-page-submit-button"
                    >
                      Submit new complaint
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
