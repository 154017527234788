import { components } from 'react-select';
import * as icons from 'resources/icons';

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <img alt="downward pointing triangle" src={icons.downTriangleWhite} />
    </components.DropdownIndicator>
  );
};
