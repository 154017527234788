import { Infringement } from './Infringement';
import { FilterList } from './FilterList';
import { Network } from './Modules.models';

export enum FormActionTypes {
  Change = 'change',
  Toggle = 'toggle',
  Add = 'add',
  Remove = 'remove',
  Validate = 'validate',
}

interface FormAction {
  field: string;
  value: any;
  type: FormActionTypes;
}

export class FormError {
  field: string;
  error: string;
  toastError: string;

  constructor(field: string, error: string, toastError: string) {
    this.field = field;
    this.error = error;
    this.toastError = toastError;
  }
}

export enum ComplainantType {
  None,
  Individual,
  Organization,
  Government,
}

export enum FileType {
  NotAvailable = 'N/A',
  Text = 'text',
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
  Other = 'other',
}

export enum OnBehalfOf {
  None,
  Self,
  OtherParty,
}

export enum ComplaintType {
  None = 0,
  Copyright = 1,
  Inappropriate = 2,
  Other = 3,
}

export enum ComplaintStatus {
  'New',
  'UnderReview',
  'Resolved',
  'Spam',
}

export enum ComplaintTypeFilter {
  All = 'All',
  Copyright = 'Copyright Infringement',
  Inappropriate = 'Inappropriate content',
  Other = 'Other',
}

export const GLOBAL_SCOPE = 'Global';

export class Complaint {
  _id?: number;
  title: string;
  fullName: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  complainantType: ComplainantType;
  onBehalfOf: OnBehalfOf;
  address: string;
  city: string;
  state: string;
  country: string;
  type: ComplaintType;
  geoScope: string[];
  complaintDescription: string;
  workDescription: string;
  infringements: Infringement[];
  agreement: boolean;
  created?: string;
  updated?: string;
  status: ComplaintStatus;
  privateNote: string;
  submitted: boolean;
  resolvedOn: Date | null;
  filterLists: FilterList[];
  isSpam: boolean;
  networks: Network[];

  constructor() {
    this.fullName = '';
    this.title = '';
    this.companyName = '';
    this.email = '';
    this.phoneNumber = '';
    this.complainantType = ComplainantType.None;
    this.onBehalfOf = OnBehalfOf.None;
    this.address = '';
    this.city = '';
    this.state = '';
    this.country = '';
    this.type = ComplaintType.None;
    this.geoScope = [];
    this.complaintDescription = '';
    this.workDescription = '';
    this.infringements = [];
    this.agreement = false;
    this.status = ComplaintStatus.New;
    this.privateNote = '';
    this.filterLists = [];
    this.submitted = false;
    this.resolvedOn = null;
    this.isSpam = false;
    this.networks = [];
  }
}

export class ComplaintFormState extends Complaint {
  emailConfirm: string;
  signature: string;
  errors: FormError[];

  constructor() {
    super();
    this.emailConfirm = '';
    this.signature = '';
    this.errors = [];
  }
}

export const validate = (formState: ComplaintFormState): FormError[] => {
  const errors = [];

  const fieldNameMapping: {
    [key: string]: string;
  } = {
    title: 'Title',
    fullName: 'Full name',
    email: 'Email address',
    emailConfirm: 'Confirm email address',
    geoScope: 'Geographic scope',
    complaintDescription: 'Description of complaint',
    infringements: 'List of infringing CID(s)',
    complainantType: 'Complainant type',
    onBehalfOf: 'Filling on behalf of',
    type: 'Type of complaint',
    workDescription: 'Description of the original work(s)',
    signature: 'Digital signature',
    networks: 'Networks',
  };

  const checkForEmptyString = [
    'title',
    'fullName',
    'email',
    'emailConfirm',
    'complaintDescription',
  ];

  const checkForEmptyArray = ['geoScope', 'infringements', 'networks'];

  const checkForEmptyOptions = ['complainantType', 'onBehalfOf', 'type'];

  if (formState.type == ComplaintType.Copyright) {
    checkForEmptyString.push('workDescription', 'signature');
  }

  for (const field in fieldNameMapping) {
    if (
      checkForEmptyString.includes(field) ||
      checkForEmptyArray.includes(field)
    ) {
      // @ts-ignore
      if ((formState[field] as Array<any>).length === 0) {
        errors.push(
          new FormError(
            field,
            'No required fields can be empty',
            `No required fields can be empty! Please fill out the following field: ${fieldNameMapping[field]}`
          )
        );
      }
    }

    if (checkForEmptyOptions.includes(field)) {
      // @ts-ignore
      if (!parseInt(formState[field])) {
        errors.push(
          new FormError(
            field,
            'No required fields can be empty',
            `You must select an option for the following field: ${fieldNameMapping[field]}!`
          )
        );
      }
    }
  }

  if (
    formState.email !== formState.emailConfirm ||
    !formState.email ||
    !formState.emailConfirm
  ) {
    errors.push(
      new FormError(
        'email',
        'The email addresses must match',
        'The email addresses must match!'
      )
    );
    errors.push(
      new FormError(
        'emailConfirm',
        'The email addresses must match',
        'The email addresses must match!'
      )
    );
  }

  if (formState.type == ComplaintType.Copyright) {
    if (formState.signature !== formState.fullName) {
      errors.push(
        new FormError(
          'signature',
          'The signature must match your full name',
          'The signature must match your full name!'
        )
      );
    }

    if (!formState.agreement) {
      errors.push(
        new FormError(
          'agreement',
          'You must agree in order to submit a complaint',
          'You must agree in order to submit a complaint!'
        )
      );
    }
  }

  return errors;
};

export const complaintFormReducer = (
  state: ComplaintFormState,
  action: FormAction
): ComplaintFormState => {
  switch (action.type) {
    case FormActionTypes.Change:
      return {
        ...state,
        [action.field]: action.value,
      };
    case FormActionTypes.Toggle:
      return {
        ...state,
        [action.field]: !{ ...state }[action.field],
      };
    case FormActionTypes.Add:
      const addField = { ...state }[action.field];
      if (Array.isArray(addField)) {
        return {
          ...state,
          [action.field]: [...addField, ...action.value],
        };
      }
      break;
    case FormActionTypes.Remove:
      const removeField = { ...state }[action.field];
      if (Array.isArray(removeField)) {
        return {
          ...state,
          // @ts-ignore
          [action.field]: removeField.filter(
            // @ts-ignore
            (element, i) => i !== action.value
          ),
        };
      }
      break;
    case FormActionTypes.Validate:
      return {
        ...state,
        errors: validate(state),
      };
  }

  return {
    ...state,
    [action.field]: action.value,
  };
};
