import axios from 'axios';
import { serverUri } from '../config';
import { Provider, IAssessorDetails } from 'models/Provider';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';

function ProviderApiService() {
  const { apiHandler } = JWTInterceptor();
  const getProviderById = async (id: number): Promise<Provider> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/providers/id/${id}`,
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const getAssessorById = async (id?: string): Promise<IAssessorDetails> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/assessor/${id}`,
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  return {
    getProviderById,
    getAssessorById,
  };
}

export default ProviderApiService;
