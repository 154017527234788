import { ComplaintType } from 'models/Modules.models';
import { ComplaintTypeInt } from 'models/PublicComplaints';
import { Record } from 'models/Records';
import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, Form, InputGroup } from 'react-bootstrap';
import { AntSwitch } from 'common/HTMLSwitchComponent';
import { useLocation } from 'react-router-dom';
import countryList from 'react-select-country-list';
import * as icons from 'resources/icons';
import ApiService from 'services/api.service';
import { useDebounce } from 'usehooks-ts';
import TableContent from './TableContent/TableContent';
import './TableRecords.css';
import dayjs from 'dayjs';
import useCollapse from 'react-collapsed';
import { RecordsGraphs } from './RecordsGraphs';
import Select from 'react-select';
import { DropdownIndicator } from '../../Dashboard/DropdownIndicator/DropdownIndicator';
import { customStyles } from '../../Dashboard/reactSelectCustomStyles';
import makeAnimated from 'react-select/animated';
import { Region } from '../../../models/GeneralStats';
import { ComplaintTypeFilter } from 'models/Complaint';
import { NetworkType } from 'models/Common';

const animatedComponents = makeAnimated();

export interface Data {
  _id: string;
  category: string;
  companyName: string;
  fullName: string;
  assessor: string;
  geoScope: string;
  submittedOn: number;
}

export enum TimePeriodEnum {
  days7 = 'Last 7 days',
  days30 = 'Last 30 days',
  months3 = 'Last 3 months',
  months6 = 'Last 6 months',
  year = 'Last year',
}

export type Order = 'asc' | 'desc';

export interface RecordsFilter {
  complainantEmail?: string;
  assessorId?: string;
  query?: string;
  category?: ComplaintTypeFilter;
  orderBy?: keyof Data;
  order?: Order;
  network?: NetworkType | 'All';
  scope?: Region[];
  timePeriod?: TimePeriodEnum;
  page?: number;
  resultsPerPage?: number;
}

export interface RecordsPropsState {
  recordsFilter: RecordsFilter;
  recordsIdsArray: Array<string>;
}

export interface IDailyStatsItem {
  date: string;
  value: number;
}

export const TableRecords = (): JSX.Element => {
  const debounceTime = 600;
  const location = useLocation();
  const selectInputRef = useRef<any>();

  const [showVolume, setShowVolume] = useState(true);

  const { getCollapseProps, getToggleProps } = useCollapse({
    duration: 500,
    defaultExpanded: true,
    //collapsedHeight: 5,
    isExpanded: showVolume,
  });

  let filteringObjectFromState = (location.state as RecordsFilter) || {};

  const [complainantSearch, setComplainantSearch] = useState(
    filteringObjectFromState.complainantEmail || undefined
  );
  const [assessorSearch, setAssessorSearch] = useState(
    filteringObjectFromState.assessorId || undefined
  );

  const [page, setPage] = React.useState(1);
  const [recordsObject, setRecordsObject] = useState<{
    records: Record[];
    totalPages: number;
  }>({
    records: [],
    totalPages: 1,
  });
  const [dailyStats, setDailyStats] = useState<Array<IDailyStatsItem>>([]);
  const [order, setOrder] = React.useState<Order>(
    filteringObjectFromState.order || 'desc'
  );
  const [orderBy, setOrderBy] = React.useState<keyof Data>(
    filteringObjectFromState.orderBy || '_id'
  );
  const [resultsPerPage, setResultsPerPage] = React.useState(
    filteringObjectFromState.resultsPerPage || 15
  );
  const [network, setNetwork] = useState<NetworkType | 'All'>(
    filteringObjectFromState.network || 'All'
  );
  const [category, setCategory] = useState(
    filteringObjectFromState.category || ComplaintTypeFilter.All
  );
  const [scope, setScope] = useState<Region[]>(
    filteringObjectFromState.scope || [
      {
        label: 'Global',
        value: 'Global',
      },
    ]
  );
  const [timePeriod, setTimePeriod] = useState(
    filteringObjectFromState.timePeriod || TimePeriodEnum.months6
  );

  const [query, setQuery] = useState<string>(
    filteringObjectFromState.query || ''
  );
  const [showSpam, setShowSpam] = useState<boolean>(false);
  const debouncedQuery = useDebounce(query, debounceTime);

  useEffect(() => {
    const getPublicComplaints = async () => {
      let startingFrom;

      switch (timePeriod) {
        case TimePeriodEnum.days7:
          startingFrom = 7;
          break;

        case TimePeriodEnum.days30:
          startingFrom = 30;
          break;

        case TimePeriodEnum.months3:
          startingFrom = 90;
          break;

        case TimePeriodEnum.months6:
          startingFrom = 180;
          break;

        case TimePeriodEnum.year:
          startingFrom = 365;
          break;
      }

      let categoryFilter;

      switch (category) {
        case ComplaintTypeFilter.Copyright:
          categoryFilter = 1;
          break;

        case ComplaintTypeFilter.Inappropriate:
          categoryFilter = 2;
          break;

        case ComplaintTypeFilter.Other:
          categoryFilter = 3;
          break;
      }

      const data = await ApiService.getPublicComplaints({
        orderBy,
        orderDirection: order,
        category: categoryFilter,
        network: network === 'All' ? '' : network,
        regions: scope?.map((sc) => sc.value),
        startingFrom: startingFrom.toString(),
        page: page.toString(),
        itemsPerPage: resultsPerPage.toString(),
        q: query,
        email: complainantSearch,
        showSpam: showSpam ? 1 : 0,
      });

      let records: Array<Record> = data.complaints.map((e) => {
        let category = '';
        switch (e.type) {
          case ComplaintTypeInt.Copyright:
            category = ComplaintType.Copyright;
            break;

          case ComplaintTypeInt.Inappropriate:
            category = ComplaintType.Inappropriate;
            break;

          case ComplaintTypeInt.Other:
            category = ComplaintType.Other;
            break;
        }

        return {
          id: e._id.toString(),
          category,
          companyName: e.companyName,
          fullName: e.fullName,
          submittedOrResolvedOn: dayjs(
            e.submittedOn ? e.submittedOn : e.resolvedOn
          ).format('YYYY-MM-DD'),
          geoScope: e.geoScope,
          assessor: e.assessor?.businessName,
          isSpam: e.isSpam,
        };
      });

      setRecordsObject({
        records,
        totalPages: data.totalPages,
      });
    };
    getPublicComplaints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    order,
    orderBy,
    page,
    resultsPerPage,
    category,
    network,
    scope,
    timePeriod,
    debouncedQuery,
    complainantSearch,
    showSpam,
  ]);

  useEffect(() => {
    const getComplaintsDailyStats = async () => {
      let startingFrom;

      switch (timePeriod) {
        case TimePeriodEnum.days7:
          startingFrom = 7;
          break;

        case TimePeriodEnum.days30:
          startingFrom = 30;
          break;

        case TimePeriodEnum.months3:
          startingFrom = 90;
          break;

        case TimePeriodEnum.months6:
          startingFrom = 180;
          break;

        case TimePeriodEnum.year:
          startingFrom = 365;
          break;
      }

      let categoryFilter;

      switch (category) {
        case ComplaintTypeFilter.Copyright:
          categoryFilter = 1;
          break;

        case ComplaintTypeFilter.Inappropriate:
          categoryFilter = 2;
          break;

        case ComplaintTypeFilter.Other:
          categoryFilter = 3;
          break;
      }
      const data = await ApiService.getComplaintsDailyStats({
        category: categoryFilter,
        network: network === 'All' ? '' : network,
        regions: scope?.map((region: Region) => region.value),
        startingFrom: startingFrom.toString(),
        q: query,
        email: complainantSearch,
        showSpam: showSpam ? 1 : 0,
      });

      setDailyStats(data);
    };
    getComplaintsDailyStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    category,
    network,
    scope,
    timePeriod,
    debouncedQuery,
    complainantSearch,
    showSpam,
  ]);

  useEffect(() => {
    document.title = 'Looking Glass - Records';
  }, []);

  return (
    <div className="table-records-container d-flex flex-column">
      <div className="table-records-header align-items-center d-flex justify-content-between text-white px-48 py-16 w-full gap-4">
        <InputGroup className="table-records-search-input-group">
          <InputGroup.Text
            className="table-records-search-icon-container pt-8 pb-8 pl-12 pr-10"
            id="search"
          >
            <img alt="search-icon" src={icons.search} />
          </InputGroup.Text>
          <Form.Control
            className="table-records-search-input pl-0 no-outline no-box-shadow"
            placeholder="Search"
            aria-label="Search"
            aria-describedby="search"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          {/* <InputGroup.Text
                className="remove-icon-container pt-8 pb-8 pl-12 pr-10"
                id="search"
                >
                {searchQuery && (
                  <img
                  onClick={() => {
                    if (searchQuery) setSearchQuery('');
                  }}
                  className="remove-icon c-pointer no-text-select"
                  src={icons.removeList}
                  />
                  )}
                </InputGroup.Text> */}
        </InputGroup>
        <div className="table-records-header-dropdown-section d-flex gap-3">
          <div className="table-records-header-dropdown d-flex align-items-center">
            <div className="table-records-header-dropdown-text">Category:</div>
            <div className="table-records-header-dropdown-input">
              <Dropdown className="">
                <Dropdown.Toggle className="toggle-dropdown-button fs-14 lh-20 fw-600 py-6 px-16">
                  {category}
                </Dropdown.Toggle>

                <Dropdown.Menu className="toggle-menu">
                  <Dropdown.Item
                    onClick={() => setCategory(ComplaintTypeFilter.All)}
                  >
                    <div className="custom-menu-item menu-item-created">
                      {ComplaintTypeFilter.All}
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setCategory(ComplaintTypeFilter.Copyright)}
                  >
                    <div className="custom-menu-item menu-item-created">
                      {ComplaintTypeFilter.Copyright}
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      setCategory(ComplaintTypeFilter.Inappropriate)
                    }
                  >
                    <div className="custom-menu-item menu-item-created">
                      {ComplaintTypeFilter.Inappropriate}
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setCategory(ComplaintTypeFilter.Other)}
                  >
                    <div className="custom-menu-item menu-item-created">
                      {ComplaintTypeFilter.Other}
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="table-records-header-dropdown d-flex align-items-center">
            <div className="table-records-header-dropdown-text">Network:</div>
            <div className="table-records-header-dropdown-input">
              <Dropdown className="">
                <Dropdown.Toggle className="toggle-dropdown-button fs-14 lh-20 fw-600 py-6 px-16">
                  {network}
                </Dropdown.Toggle>

                <Dropdown.Menu className="toggle-menu">
                  <Dropdown.Item onClick={() => setNetwork('All')}>
                    <div className="custom-menu-item menu-item-created">
                      All
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setNetwork(NetworkType.Filecoin)}
                  >
                    <div className="custom-menu-item menu-item-created">
                      {NetworkType.Filecoin}
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setNetwork(NetworkType.IPFS)}>
                    <div className="custom-menu-item menu-item-created">
                      {NetworkType.IPFS}
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="table-records-header-dropdown d-flex align-items-center">
            <div className="table-records-header-dropdown-text">Scope:</div>
            <div>
              <Select
                components={{ ...animatedComponents, DropdownIndicator }}
                isMulti
                ref={selectInputRef}
                onChange={(e: any) => {
                  setScope(e);
                }}
                options={countryList().getData()}
                placeholder="Scope"
                styles={customStyles}
              />
            </div>
          </div>
          <div className="table-records-header-dropdown d-flex align-items-center">
            <div className="table-records-header-dropdown-text">
              Time period:
            </div>
            <div className="table-records-header-dropdown-input">
              <Dropdown className="">
                <Dropdown.Toggle className="toggle-dropdown-button fs-14 lh-20 fw-600 py-6 px-16">
                  {timePeriod}
                </Dropdown.Toggle>

                <Dropdown.Menu className="toggle-menu">
                  <Dropdown.Item
                    onClick={() => setTimePeriod(TimePeriodEnum.days7)}
                  >
                    <div className="custom-menu-item menu-item-created">
                      {TimePeriodEnum.days7}
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setTimePeriod(TimePeriodEnum.days30)}
                  >
                    <div className="custom-menu-item menu-item-created">
                      {TimePeriodEnum.days30}
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setTimePeriod(TimePeriodEnum.months3)}
                  >
                    <div className="custom-menu-item menu-item-created">
                      {TimePeriodEnum.months3}
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setTimePeriod(TimePeriodEnum.months6)}
                  >
                    <div className="custom-menu-item menu-item-created">
                      {TimePeriodEnum.months6}
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setTimePeriod(TimePeriodEnum.year)}
                  >
                    <div className="custom-menu-item menu-item-created">
                      {TimePeriodEnum.year}
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="table-records-header-dropdown d-flex align-items-center">
            <div
              onClick={() => {
                setTimePeriod(TimePeriodEnum.days30);
                setScope([
                  {
                    label: 'Global',
                    value: 'Global',
                  },
                ]);
                setPage(1);
                setOrderBy('_id');
                setOrder('desc');
                setResultsPerPage(15);
                setAssessorSearch(undefined);
                setComplainantSearch(undefined);
                setQuery('');
                setCategory(ComplaintTypeFilter.All);
                selectInputRef.current.setValue(null);
              }}
              className="table-records-header-dropdown-text clear-filters-button d-flex justify-content-center align-items-center text-align-center height-fit-content"
            >
              Clear filters
            </div>
          </div>
          <div className="table-records-header-dropdown d-flex align-items-center">
            <div className="table-records-header-dropdown-text">Graphs:</div>
            <AntSwitch
              checked={showVolume}
              {...getToggleProps({
                onClick: () => setShowVolume((showVolume) => !showVolume),
              })}
              size="medium"
              name="show-volume-graphs"
            />
          </div>
          <div className="table-records-header-dropdown d-flex align-items-center">
            <div className="table-records-header-dropdown-text">Spam:</div>
            <AntSwitch
              checked={showSpam}
              {...getToggleProps({
                onClick: () => setShowSpam((showSpam) => !showSpam),
              })}
              size="medium"
              name="show-spam"
            />
          </div>
        </div>
      </div>
      <div {...getCollapseProps()}>
        <RecordsGraphs stats={dailyStats} />
      </div>
      <TableContent
        records={recordsObject.records}
        totalPages={recordsObject.totalPages}
        setPage={setPage}
        page={page}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        order={order}
        setOrder={setOrder}
        resultsPerPage={resultsPerPage}
        setResultsPerPage={setResultsPerPage}
        recordsFilter={{
          complainantEmail: complainantSearch,
          assessorId: assessorSearch,
          query: debouncedQuery,
          category,
          orderBy,
          order,
          scope,
          timePeriod,
          page,
          resultsPerPage,
        }}
        recordsIdsArray={recordsObject.records.map((e) => e.id)}
      />
    </div>
  );
};
