import axios from 'axios';
import { Complaint } from 'models/Complaint';
import { serverUri } from '../config';

export interface RelatedCidComplaint {
  infringement: string;
  complaints: Complaint[];
}

export interface RelatedComplaintsResponse {
  complainant: Complaint[];
  cids: RelatedCidComplaint[];
}

export const getComplaintById = async (id: number): Promise<Complaint> => {
  const response = await axios.get(`${serverUri()}/complaints/${id}`);

  return response.data;
};

export const saveComplaint = async (
  complaint: Complaint
): Promise<Complaint> => {
  return await axios.post(`${serverUri()}/complaints`, complaint);
};

export const apiFunctions = {
  getComplaintById,
  saveComplaint,
};
