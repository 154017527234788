import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { useLayoutEffect } from 'react';

interface BarProps {
  name: string;
  height?: number;
  textColorWhite?: boolean;
  data: Array<{
    date: string;
    value: number;
  }>;
}

export function Bar(props: BarProps) {
  useLayoutEffect(() => {
    am5.addLicense('AM5C333440217');
    const data = [...props.data];
    const root = am5.Root.new(`bar-chart-${props.name}`);

    const chart = root.container.children.push(am5xy.XYChart.new(root, {}));

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: false,
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          stroke: am5.color(0xffffff),
          visible: false,
        }),
      })
    );

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.2,
        renderer: am5xy.AxisRendererY.new(root, {
          stroke: am5.color(0xffffff),
        }),
      })
    );
    const xRenderer = xAxis.get('renderer');
    xRenderer.grid.template.setAll({
      width: 0,
      visible: false,
    });

    const yRenderer = yAxis.get('renderer');
    yRenderer.grid.template.setAll({
      stroke: am5.color(0xffffff),
      strokeDasharray: [2, 2],
    });

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        minBulletDistance: 10,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText: '{valueY}',
        }),
      })
    );

    series.data.processor = am5.DataProcessor.new(root, {
      dateFormat: 'yyyy-MM-dd',
      dateFields: ['date'],
    });

    series.data.setAll(data);

    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none',
      })
    );

    cursor.lineY.set('visible', false);
    cursor.lineX.set('visible', false);
    root.interfaceColors.set('text', am5.color(0x6a737d));

    return () => {
      root.dispose();
    };
  });

  return (
    <div
      id={`bar-chart-${props.name}`}
      style={{ width: '100%', height: props.height || 300 }}
    ></div>
  );
}
