import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
// import am5themes_Dark from '@amcharts/amcharts5/themes/Dark';
import React, { useLayoutEffect } from 'react';

interface DonutProps {
  name: string;
  colors: number[];
  height?: number;
  textColorWhite?: boolean;
  data: Array<{
    count: number;
  }>;
  showPercentages?: boolean;
}

export function DonutChart(props: DonutProps) {
  useLayoutEffect(() => {
    am5.addLicense('AM5C333440217');
    const data = [...props.data];
    const { showPercentages } = props;
    const root = am5.Root.new(`donut-chart-${props.name}`);

    // root.setThemes([am5themes_Dark.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        innerRadius: am5.percent(50),
        height: 300,
        centerY: 50,
      })
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'count',
        alignLabels: false,
        radius: 27,
        tooltip: am5.Tooltip.new(root, {
          forceHidden: true,
        }),
        hoverOnFocus: false,
      })
    );

    series.ticks.template.setAll({
      forceHidden: true,
    });

    series.get('colors')?.set(
      'colors',
      props.colors.map((e) => am5.color(e))
    );

    if (showPercentages) {
      props.textColorWhite
        ? series.labels.template.setAll({
            textType: 'circular',
            radius: -17,
            fill: am5.color(0xffffff),
            fontSize: 10,
            text: '{count}%',
          })
        : series.labels.template.setAll({
            textType: 'circular',
            radius: -17,
            fontSize: 10,
            text: '{count}%',
          });
    } else {
      series.labels.template.setAll({
        forceHidden: true,
      });
    }

    series.data.setAll(data);

    series.slicesContainer.events.disableType('click');

    series.slices.template.states.create('active', {
      active: false,
    });

    series.slices.template.states.create('hover', {
      active: false,
    });

    // root.addDisposer(
    //   am5.utils.addEventListener(root.dom, 'click', function (ev) {
    //     ev.preventDefault();
    //   }),
    // );

    return () => {
      root.dispose();
    };
  });

  return (
    <div
      id={`donut-chart-${props.name}`}
      style={{ width: '100%', height: props.height || 300 }}
    ></div>
  );
}
