import { Home } from 'modules/Home/Home';
import { Dashboard } from 'modules/Dashboard/Dashboard';
import { TableRecords } from 'modules/Records/TableRecords/TableRecords';
import { IndividualRecord } from 'modules/Records/IndividualRecord/IndividualRecord';
import ComplaintPage from 'modules/ComplaintPage';
import { ThankYou } from 'modules/ComplaintPage/ThankYou/ThankYou';
import { Assessors } from 'modules/Assessors/Assessors';
import { AssessorDetails } from 'modules/AssessorDetails/AssessorDetails';

export const publicRoutes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/records',
    element: <TableRecords />,
  },
  {
    path: '/records/:id',
    element: <IndividualRecord />,
  },
  {
    path: '/assessors/:id',
    element: <AssessorDetails />,
  },
  {
    path: '/complaint',
    element: <ComplaintPage />,
  },
  {
    path: '/complaint/thank-you',
    element: <ThankYou />,
  },
  {
    path: '/assessors',
    element: <Assessors />,
  },
];
