import axios from 'axios';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { serverUri } from '../../config';
import {
  LOCAL_STORAGE_CONTEXT,
  UserContext,
  UserContextActionTypes,
} from 'context/UserContext';

const instance = axios.create();

instance.interceptors.request.use((request) => {
  const storedContextString = localStorage.getItem(LOCAL_STORAGE_CONTEXT);
  if (storedContextString) {
    const context = JSON.parse(storedContextString);
    const account = context.account;
    const isLoggedIn = account && account.accessToken;
    const isApiUrl = request.url && request.url.startsWith(serverUri());

    if (request && request.headers && isLoggedIn && isApiUrl) {
      request.headers.Authorization = `Bearer ${account.accessToken}`;
    }
  }
  return request;
});

export function JWTInterceptor() {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const apiHandler = useCallback(
    async (config: {
      method: 'get' | 'patch' | 'post' | 'delete' | 'put';
      url: string;
      data?: any;
      axiosConfig?: any;
      successMessage?: string;
      errorMessage?: string;
    }): Promise<{
      data?: any;
      error: string | null;
    }> => {
      return new Promise((resolve, reject) => {
        try {
          let apiCall;

          if (config.data && config.axiosConfig) {
            apiCall = instance[config.method](
              config.url,
              config.data,
              config.axiosConfig
            );
          } else if (config.data) {
            apiCall = instance[config.method](config.url, config.data);
          } else if (config.axiosConfig) {
            apiCall = instance[config.method](config.url, config.axiosConfig);
          } else {
            apiCall = instance[config.method](config.url);
          }

          apiCall
            .then((res) => {
              if (config.successMessage) {
                toast.success(config.successMessage);
              }
              resolve({
                data: res.data,
                error: null,
              });
            })
            .catch((e) => {
              if (e.response && [401, 403].includes(e.response.status)) {
                toast.error(
                  'Authentication token is invalid or expired. Please relog!'
                );
                userContext.dispatch({ type: UserContextActionTypes.LogOut });
                navigate('/login');
                return reject({
                  error:
                    'Authentication token is invalid or expired. Please relog!',
                });
              }

              toast.error('Something went wrong! Please try again later.');
              reject('Something went wrong! Please try again later.');
            });
        } catch (e) {
          if (!config || !config.method) {
            toast.error('Invalid request!');
            reject('Invalid request!');
          }
          reject('Unknown issue, please contact support!');
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    apiHandler,
  };
}
