import { captchaKey } from 'config';
import { NetworkType } from 'models/Common';
import {
  ComplainantType,
  ComplaintFormState,
  ComplaintType,
  FileType,
  FormActionTypes,
  FormError,
  GLOBAL_SCOPE,
  OnBehalfOf,
  complaintFormReducer,
} from 'models/Complaint';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { Button, CloseButton, Col, Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import countryList from 'react-select-country-list';
import { toast } from 'react-toastify';
import './ComplaintForm.css';
import { apiFunctions } from 'services/complaints-portal-api.service';

export const ComplaintForm = (props: any): JSX.Element => {
  const navigate = useNavigate();
  const countries = useMemo(
    () => Object.values(countryList().getValueList()),
    []
  );
  const [formState, dispatch] = useReducer(
    complaintFormReducer,
    new ComplaintFormState()
  );
  const [shouldSubmit, setShouldSubmit] = useState<boolean>(false);
  const [newCid, setNewCid] = useState('');
  const [newCidType, setNewCidType] = useState('N/A');
  const [captchaCompleted, setCaptchaCompleted] = useState<boolean>(false);

  useEffect(() => {
    if (!shouldSubmit) {
      return;
    }
    setShouldSubmit(false);

    if (formState.errors.length === 0) {
      apiFunctions.saveComplaint(formState).then((response) => {
        navigate('/complaint/thank-you');
      });
    } else {
      toast.error(formState.errors[0].toastError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  const addInfringement = (infringement: string) => {
    const infringementArray = infringement.trim().split(/[\r\n\s]+/);
    dispatch({
      type: FormActionTypes.Add,
      field: 'infringements',
      value: infringementArray.map((infringement) => ({
        value: infringement,
        fileType: newCidType,
      })),
    });
  };

  const removeInfringement = (index: number) => {
    dispatch({
      type: FormActionTypes.Remove,
      field: 'infringements',
      value: index,
    });
  };

  const dispatchChange = (e: any) => {
    dispatch({
      type: FormActionTypes.Change,
      field: e.target.id,
      value: e.target.value,
    });
  };

  const dispatchToggle = (e: any) => {
    dispatch({
      type: FormActionTypes.Toggle,
      field: e.target.id,
      value: e.target.value,
    });
  };

  const dispatchCountryChange = (e: any) => {
    dispatch({
      type: FormActionTypes.Change,
      field: 'country',
      value:
        e.length > 0
          ? countryList()
              .getData()
              .find((el) => el.label === e[0])?.value
          : '',
    });
  };

  const dispatchScopeChange = (e: any) => {
    const scopeArray = e.map((country: any) => {
      if (country === 'Global') return country;
      return countryList()
        .getData()
        .find((el) => el.label === country)?.value;
    });

    dispatch({
      type: FormActionTypes.Change,
      field: 'geoScope',
      value: scopeArray,
    });
  };

  const dispatchNetworksChange = (e: any) => {
    dispatch({
      type: FormActionTypes.Change,
      field: 'networks',
      value: e,
    });
  };

  const handleSubmit = () => {
    if (props.testing) {
      return props.testFunction();
    }
    if (!formState.infringements.length && newCid.length) {
      return toast.error(
        'To submit your complaint, first click Add items and try again'
      );
    }
    setShouldSubmit(true);
    dispatch({
      type: FormActionTypes.Validate,
      field: '',
      value: '',
    });
  };

  const getErrorForField = (field: string): FormError => {
    return (
      formState.errors.find((elem: FormError) => elem.field === field) ||
      new FormError('', '', '')
    );
  };

  const isFieldValid = (field: string): boolean => {
    const error = getErrorForField(field);

    return error.error.length === 0;
  };

  const onCaptchaChange = (value: any): void => {
    if (value) {
      setCaptchaCompleted(true);
    } else {
      setCaptchaCompleted(false);
    }
  };

  return (
    <>
      <Col>
        <Form>
          <div className="form-section">
            <Row className="section-header">Complaint information</Row>
            <Row className="form-row">
              <Col>
                <Form.Group className="mb-3" controlId="title">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    className="form-input"
                    aria-label="complaint-form-title-input"
                    type="text"
                    placeholder="Write a complaint title..."
                    value={formState.title}
                    onChange={(e) => dispatchChange(e)}
                    isInvalid={!isFieldValid('title')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {getErrorForField('title').error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="form-row">
              <Col>
                <Form.Group className="mb-3" controlId="fullName">
                  <Form.Label>Your full name</Form.Label>
                  <Form.Control
                    className="form-input"
                    aria-label="complaint-form-fullname-input"
                    type="text"
                    placeholder="Write your full name..."
                    value={formState.fullName}
                    onChange={(e) => dispatchChange(e)}
                    isInvalid={!isFieldValid('fullName')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {getErrorForField('fullName').error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="form-row">
              <Col>
                <Form.Group className="mb-3" controlId="companyName">
                  <Form.Label>Company name </Form.Label>
                  <Form.Text> (optional)</Form.Text>
                  <Form.Control
                    className="form-input"
                    aria-label="complaint-form-company-input"
                    type="text"
                    placeholder="Write company name..."
                    value={formState.companyName}
                    onChange={(e) => dispatchChange(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="form-row">
              <Col>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    className="form-input"
                    aria-label="complaint-form-email-input"
                    type="text"
                    placeholder="Write email address..."
                    value={formState.email}
                    onChange={(e) => dispatchChange(e)}
                    isInvalid={!isFieldValid('email')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {getErrorForField('email').error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="emailConfirm">
                  <Form.Label>Confirm email address</Form.Label>
                  <Form.Control
                    className="form-input"
                    type="text"
                    aria-label="complaint-form-confirm-input"
                    placeholder="Confirm email address..."
                    value={formState.emailConfirm}
                    onChange={(e) => dispatchChange(e)}
                    isInvalid={!isFieldValid('emailConfirm')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {getErrorForField('emailConfirm').error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="phoneNumber">
                  <Form.Label>Phone number </Form.Label>
                  <Form.Text> (optional)</Form.Text>
                  <Form.Control
                    className="form-input"
                    aria-label="complaint-form-phone-input"
                    type="text"
                    placeholder="Write phone number..."
                    value={formState.phoneNumber}
                    onChange={(e) => dispatchChange(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="form-row">
              <Col>
                <Form.Group className="mb-3" controlId="complainantType">
                  <Form.Label>Complainant type</Form.Label>
                  <Form.Select
                    className="form-input"
                    aria-label="complaint-form-complaint-input"
                    value={formState.complainantType}
                    onChange={(e) => dispatchChange(e)}
                    isInvalid={!isFieldValid('complainantType')}
                  >
                    <option value={ComplainantType.None}>
                      Select an option
                    </option>
                    <option value={ComplainantType.Individual}>
                      Individual
                    </option>
                    <option value={ComplainantType.Organization}>
                      Organization
                    </option>
                    <option value={ComplainantType.Government}>
                      Government
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {getErrorForField('complainantType').error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="onBehalfOf">
                  <Form.Label>Filling on behalf of</Form.Label>
                  <Form.Select
                    className="form-input"
                    aria-label="complaint-form-behalf-input"
                    value={formState.onBehalfOf}
                    onChange={(e) => dispatchChange(e)}
                    isInvalid={!isFieldValid('onBehalfOf')}
                  >
                    <option value={OnBehalfOf.None}>Select an option</option>
                    <option value={OnBehalfOf.Self}>Self</option>
                    <option value={OnBehalfOf.OtherParty}>Other party</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {getErrorForField('onBehalfOf').error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="form-row">
              <Col>
                <Form.Group className="mb-3" controlId="address">
                  <Form.Label>Address </Form.Label>
                  <Form.Text> (optional)</Form.Text>
                  <Form.Control
                    className="form-input"
                    aria-label="complaint-form-address-input"
                    type="text"
                    placeholder="Write address..."
                    value={formState.address}
                    onChange={(e) => dispatchChange(e)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="city">
                  <Form.Label>City </Form.Label>
                  <Form.Text> (optional)</Form.Text>
                  <Form.Control
                    className="form-input"
                    aria-label="complaint-form-city-input"
                    type="text"
                    placeholder="Write city..."
                    value={formState.city}
                    onChange={(e) => dispatchChange(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="form-row">
              <Col>
                <Form.Group className="mb-3" controlId="state">
                  <Form.Label>State / province </Form.Label>
                  <Form.Text> (optional)</Form.Text>
                  <Form.Control
                    className="form-input"
                    aria-label="complaint-form-state-input"
                    type="text"
                    placeholder="Write state / province..."
                    value={formState.state}
                    onChange={(e) => dispatchChange(e)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="country">
                  <Form.Label>Country</Form.Label>
                  <Form.Text> (optional)</Form.Text>
                  <Typeahead
                    aria-label="complaint-form-country-input"
                    id="country-selector"
                    options={countries}
                    onChange={dispatchCountryChange}
                    allowNew={false}
                    placeholder={'Select country'}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="form-section">
            <Row className="section-header">Details of complaint</Row>
            <Row className="form-row">
              <Col>
                <Form.Group className="mb-3" controlId="type">
                  <Form.Label>Type of complaint</Form.Label>
                  <Form.Select
                    className="form-input"
                    aria-label="complaint-form-type-input"
                    value={formState.type}
                    onChange={(e) => dispatchChange(e)}
                    isInvalid={!isFieldValid('type')}
                  >
                    <option value={ComplaintType.None}>Select an option</option>
                    <option value={ComplaintType.Copyright}>Copyright</option>
                    <option value={ComplaintType.Inappropriate}>
                      Inappropriate content
                    </option>
                    <option value={ComplaintType.Other}>Other</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {getErrorForField('type').error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="geoScope">
                  <Form.Label>Geographic scope</Form.Label>
                  <Typeahead
                    aria-label="complaint-form-scope-input"
                    multiple
                    clearButton
                    className={!isFieldValid('geoScope') ? 'is-invalid' : ''}
                    id="scope-selector"
                    options={[GLOBAL_SCOPE, ...countries]}
                    onChange={dispatchScopeChange}
                    allowNew={false}
                    isInvalid={!isFieldValid('geoScope')}
                    placeholder={'Select a geographic scope'}
                  />
                  <Form.Control.Feedback type="invalid">
                    {getErrorForField('geoScope').error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="form-row">
              <Col>
                <Form.Group className="mb-3" controlId="complaintDescription">
                  <Form.Label>Description of complaint</Form.Label>
                  <Form.Control
                    className="form-input"
                    aria-label="complaint-form-description-input"
                    as="textarea"
                    style={{ height: '100px' }}
                    placeholder="Write description..."
                    value={formState.complaintDescription}
                    onChange={(e) => dispatchChange(e)}
                    isInvalid={!isFieldValid('complaintDescription')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {getErrorForField('complaintDescription').error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {Number(formState.type) === ComplaintType.Copyright && (
              <Row className="form-row">
                <Col>
                  <Form.Group className="mb-3" controlId="workDescription">
                    <Form.Label>Description of the original work(s)</Form.Label>
                    <Form.Control
                      className="form-input"
                      aria-label="complaint-form-original-input"
                      as="textarea"
                      placeholder="Write description..."
                      value={formState.workDescription}
                      onChange={(e) => dispatchChange(e)}
                      isInvalid={!isFieldValid('workDescription')}
                    />
                    <Form.Control.Feedback type="invalid">
                      {getErrorForField('workDescription').error}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row className="form-row">
              <Col>
                <Form.Group className="mb-3" controlId="cid">
                  <Form.Label>List of infringing CID(s) </Form.Label>
                  <Row>
                    <Col>
                      <ul>
                        {formState.infringements.map((infringement, index) => {
                          return (
                            <li>
                              {infringement.value} ({infringement.fileType})
                              <CloseButton
                                variant="white"
                                key={infringement.value}
                                aria-label="Remove CID"
                                onClick={() => {
                                  removeInfringement(index);
                                }}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </Col>
                  </Row>
                  <div className="add-cid-row">
                    <Form.Control
                      className="form-input add-cid-value-input"
                      as="textarea"
                      style={{ resize: 'none' }}
                      rows={4}
                      placeholder="CID"
                      value={newCid}
                      onChange={(e) => setNewCid(e.target.value)}
                    />

                    <Form.Select
                      className="add-cid-type-input"
                      value={newCidType}
                      onChange={(e) => setNewCidType(e.target.value)}
                    >
                      <option value={FileType.NotAvailable}>File type</option>
                      <option value={FileType.Text}>Text</option>
                      <option value={FileType.Image}>Image</option>
                      <option value={FileType.Video}>Video</option>
                      <option value={FileType.Audio}>Audio</option>
                      <option value={FileType.Other}>Other</option>
                    </Form.Select>
                    <Button
                      aria-label="complaint-form-add-cid-button"
                      className="add-cid-button"
                      variant="primary"
                      onClick={() => {
                        if (!newCid) {
                          return toast.error(
                            'You must add a value to the CID input in order to submit a new item to the list!'
                          );
                        }

                        if (newCidType === FileType.NotAvailable) {
                          return toast.error(
                            'You must select a valid type in order to submit a new item to the list!'
                          );
                        }
                        addInfringement(newCid);
                        setNewCid('');
                        setNewCidType(FileType.NotAvailable);
                      }}
                    >
                      Add item(s)
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="form-row">
              <Col>
                <Form.Group className="mb-3" controlId="networks">
                  <Form.Label>Networks</Form.Label>
                  <Typeahead
                    aria-label="complaint-form-networks-input"
                    multiple
                    clearButton
                    className={!isFieldValid('networks') ? 'is-invalid' : ''}
                    id="scope-selector"
                    options={Object.keys(NetworkType)}
                    onChange={dispatchNetworksChange}
                    allowNew={false}
                    isInvalid={!isFieldValid('networks')}
                    placeholder={
                      'Select the networks for which the complaint applies'
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {getErrorForField('networks').error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            {Number(formState.type) === ComplaintType.Copyright && (
              <>
                <Row className="form-row">
                  <Col>
                    <Form.Group className="mb-3" controlId="agreement">
                      <Form.Label>
                        512(f) acknowledgment, Good faith belief, Authority to
                        act
                      </Form.Label>
                      <br />
                      <Form.Text className="text-muted">
                        By checking this box, you attest, under penalty of
                        perjury, that you have a good faith belief that use of
                        the material in this report is not authorized by the
                        copyright owner, its agent, or the law; AND you are
                        authorized to act on behalf of the copyright owner; AND
                        you understand, under 17 U.S.C. § 512(f), you may be
                        liable for any damages, including costs and attorneys'
                        fees, if you knowingly materially misrepresent reported
                        material.
                      </Form.Text>
                      <Form.Check
                        type="checkbox"
                        label="I understand and agree"
                        checked={formState.agreement}
                        onChange={(e) => dispatchToggle(e)}
                        isInvalid={!isFieldValid('agreement')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {getErrorForField('agreement').error}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col>
                    <Form.Group className="mb-3" controlId="signature">
                      <Form.Label>Digital signature</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        placeholder="Enter your name to sign this submission"
                        value={formState.signature}
                        onChange={(e) => dispatchChange(e)}
                        isInvalid={!isFieldValid('signature')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {getErrorForField('signature').error}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            <Row className="form-final-row">
              <Col>
                <ReCAPTCHA sitekey={captchaKey()} onChange={onCaptchaChange} />
              </Col>
              <Col>
                <Button
                  aria-label="complaint-form-submit-button"
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={!captchaCompleted}
                >
                  Submit complaint
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Col>
    </>
  );
};
