import * as am5 from '@amcharts/amcharts5';
import am5geodata_data_countries2 from '@amcharts/amcharts5-geodata/data/countries2';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import React, { useLayoutEffect } from 'react';

interface WorldMapProps {
  name: string;
  data: Array<{
    id: string;
  }>;
  color?: string;
}

export function WorldMap(props: WorldMapProps) {
  useLayoutEffect(() => {
    am5.addLicense('AM5M333440217');

    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    const root = am5.Root.new(`world-map-${props.name}`);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create the map chart
    // https://www.amcharts.com/docs/v5/charts/map-chart/
    const chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: 'none',
        panY: 'none',
        projection: am5map.geoMercator(),
        maxZoomLevel: 1,
        minZoomLevel: 1,
      })
    );

    // Create polygon series for the world map
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
    const worldSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
        exclude: ['AQ'],
      })
    );

    worldSeries.mapPolygons.template.setAll({
      tooltipText: '{name}',
      toggleKey: 'active',
      interactive: true,
      stroke: am5.color(0x000),
      tooltip: am5.Tooltip.new(root, {
        forceHidden: true,
      }),
      fill: am5.color(0x444d56),
      templateField: 'polygonSettings',
    });

    // Set up data for countries
    const data: any = [];
    for (let i = 0; i < props.data.length; i++) {
      const country = am5geodata_data_countries2[props.data[i].id];
      if (country.maps.length) {
        data.push({
          id: props.data[i].id,
          polygonSettings: {
            fill: am5.color(
              props.color ? Number(`0x${props.color}`) : 0x0366d6
            ),
          },
        });
      }
    }
    worldSeries.data.setAll(data);

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  });

  return (
    <div
      id={`world-map-${props.name}`}
      style={{ width: '100%', height: '350px' }}
    ></div>
  );
}
