import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import { GenericPerDayItem } from 'models/Modules.models';
import {
  Data,
  Order,
  TimePeriodEnum,
} from 'modules/Records/TableRecords/TableRecords';
import React, { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface OnClickConfig {
  orderBy: keyof Data;
  order: Order;
  timePeriod?: TimePeriodEnum;
}
interface StackedLineGraphProps {
  name: string;
  height?: number;
  width?: number;
  centerY?: number;
  data: Array<GenericPerDayItem>;
  onClickConfig?: OnClickConfig;
}

export function StackedLineGraph(props: StackedLineGraphProps) {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    am5.addLicense('AM5C333440217');
    const root = am5.Root.new(`stacked-line-${props.name}`);
    const data = [...props.data];

    root.setThemes([am5themes_Animated.new(root)]);

    root.dateFormatter.setAll({
      dateFormat: 'yyyy',
      dateFields: ['valueX'],
    });

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        height: 70,
        centerY: props.centerY || -10,
      })
    );

    chart.get('colors')?.set('colors', [am5.color(0xe36209)]);

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: false,
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },

        renderer: am5xy.AxisRendererX.new(root, {}),
        // tooltip: am5.Tooltip.new(root, {
        //   pointTo: {
        //     x: 0,
        //     y: -100,
        //   },
        // }),
        forceHidden: true,
      })
    );

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.2,
        renderer: am5xy.AxisRendererY.new(root, {}),
        forceHidden: true,
      })
    );

    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        minBulletDistance: 5,
        connect: false,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText: '{valueY}',
        }),
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'count',
        valueXField: 'date',
      })
    );

    series.fills.template.setAll({
      fillOpacity: 0.2,
      visible: true,
      fillGradient: am5.LinearGradient.new(root, {
        stops: [
          {
            opacity: 1,
          },
          {
            opacity: 0,
          },
        ],
      }),
    });

    series.strokes.template.setAll({
      strokeWidth: 1,
    });

    // series.states.remove('hover');

    series.data.processor = am5.DataProcessor.new(root, {
      dateFormat: 'yyyy-MM-dd',
      dateFields: ['date'],
    });
    series.data.setAll(data);

    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        behavior: 'none',
      })
    );
    cursor.lineY.set('visible', false);

    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  });

  return (
    <div
      onClick={() => {
        if (!props.onClickConfig) return;

        const { timePeriod, orderBy, order } = props.onClickConfig;
        const state: OnClickConfig = {
          orderBy,
          order,
        };

        if (timePeriod) {
          state.timePeriod = timePeriod;
        }

        navigate('/records', {
          replace: true,
          state,
        });
      }}
      id={`stacked-line-${props.name}`}
      style={{ width: props.width || '100%', height: props.height || 120 }}
    ></div>
  );
}
