import './AdditionalResources.css';

export const AdditionalResources = (props: any): JSX.Element => {
  return (
    <div className="home-second-page-upper-resources d-flex flex-column justify-content-between">
      <div className="resources-title fs-16 lh-24 fw-700">
        Additional resources
      </div>
      {/* commented until ipfs is resolved */}
      {/* <div className="resources-link c-pointer fs-14 lh-20 fw-600">
        <a
          href="https://github.com/Murmuration-Labs/looking-glass-docs/blob/main/what-is-ipfs.md"
          target="_blank"
          rel="noreferrer"
        >
          What is IPFS?
        </a>
      </div> */}
      <div className="resources-link c-pointer fs-14 lh-20 fw-600">
        <a
          href="https://github.com/Murmuration-Labs/looking-glass-docs/blob/main/what-is-filecoin.md"
          target="_blank"
          rel="noreferrer"
        >
          What is Filecoin?
        </a>
      </div>
      <div className="resources-link c-pointer fs-14 lh-20 fw-600">
        <a
          href="https://github.com/Murmuration-Labs/looking-glass-docs/blob/main/about-bitscreen-filtering.md"
          target="_blank"
          rel="noreferrer"
        >
          About BitScreen Filtering
        </a>
      </div>
      <div className="resources-link c-pointer fs-14 lh-20 fw-600">
        <a
          href="https://github.com/Murmuration-Labs/looking-glass-docs/blob/main/how-are-complaints-assessed.md"
          target="_blank"
          rel="noreferrer"
        >
          How are complaints assessed?
        </a>
      </div>
      <div className="resources-link c-pointer fs-14 lh-20 fw-600">
        <a
          href="https://github.com/Murmuration-Labs/looking-glass-docs/blob/main/how-to-file-a-complaint.md"
          target="_blank"
          rel="noreferrer"
        >
          How to file a complaint
        </a>
      </div>
      <div className="resources-link c-pointer fs-14 lh-20 fw-600">
        <a
          href="https://github.com/Murmuration-Labs/looking-glass-docs/blob/main/apply-to-become-an-assessor.md"
          target="_blank"
          rel="noreferrer"
        >
          Apply to become an assessor
        </a>
      </div>
    </div>
  );
};
