import { bitscreenUri, rodeoUri } from 'config';
import { Dropdown } from 'react-bootstrap';
import * as icons from 'resources/icons';

export const HeaderAppSwitcher = () => {
  return (
    <div className="d-flex actions-button pr-22">
      <Dropdown align={'end'} className="top-bar-menu-dropdown">
        <Dropdown.Toggle className="top-bar-menu-dropdown-button d-flex align-items-center justify-content-center c-pointer no-text-select py-8">
          <img className="c-pointer" src={icons.menu} alt="menu"></img>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            disabled={true}
            className="top-bar-menu-dropdown-title-item fs-10 lh-12"
            eventKey="1"
          >
            APP SWITCHER
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="1"
            onClick={() => window.open(bitscreenUri())}
          >
            BitScreen
          </Dropdown.Item>
          <Dropdown.Item eventKey="1" onClick={() => window.open(rodeoUri())}>
            Rodeo
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
