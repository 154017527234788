import { createContext, Dispatch, useReducer } from 'react';
import { Account } from 'models/Account';
import { Config } from 'models/Config';

export const LOCAL_STORAGE_CONTEXT = 'rodeo_context';

export enum UserContextActionTypes {
  ToggleTheme = 'TOGGLE_THEME',
  SetAccount = 'SET_ACCOUNT',
  LogOut = 'LOG_OUT',
  SetConfig = 'SET_CONFIG',
}

export enum Theme {
  Dark = 'dark',
  Light = 'light',
}

export interface UserContextState {
  theme: Theme;
  account?: Account;
  config?: Config;
}

export interface ContextAction {
  type: UserContextActionTypes;
  value?: any;
}

const userContextReducer = (
  state: UserContextState,
  action: ContextAction
): UserContextState => {
  let newState = state;
  switch (action.type) {
    case UserContextActionTypes.ToggleTheme:
      newState = {
        ...state,
        theme: state.theme === Theme.Light ? Theme.Dark : Theme.Light,
      };
      break;
    case UserContextActionTypes.SetAccount:
      newState = {
        ...state,
        account: action.value,
      };
      break;
    case UserContextActionTypes.LogOut:
      newState = {
        ...state,
        account: undefined,
        config: undefined,
      };
      break;
    case UserContextActionTypes.SetConfig:
      newState = {
        ...state,
        config: action.value,
      };
  }
  localStorage.setItem(LOCAL_STORAGE_CONTEXT, JSON.stringify(newState));

  return { ...newState };
};

const initialState = {
  theme: Theme.Light,
};

const getInitialContext = (): UserContextState => {
  let context = initialState;
  const storedContextString = localStorage.getItem(LOCAL_STORAGE_CONTEXT);

  if (storedContextString) {
    const storedContext = JSON.parse(storedContextString);
    context = {
      ...context,
      ...storedContext,
    };
  }

  return context;
};

export const UserContext = createContext<{
  state: UserContextState;
  dispatch: Dispatch<ContextAction>;
}>({
  state: getInitialContext(),
  dispatch: () => null,
});

export const UserContextProvider = (props: any): JSX.Element => {
  const [state, dispatch] = useReducer(userContextReducer, getInitialContext());

  return (
    <UserContext.Provider value={{ state: state, dispatch: dispatch }}>
      {props.children}
    </UserContext.Provider>
  );
};
