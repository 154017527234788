import { NetworkType } from './Common';
import { FileType } from './Complaint';
import { GenericCountItem, GenericPerDayItemResponse } from './GeneralStats';

export enum Network {
  IPFS = 'IPFS',
  Filecoin = 'Filecoin',
}

export interface CidsReviewedStackedLineItem {
  count: string | number;
}

export interface GenericPerDayItem {
  count: number;
  date: string;
}

export interface CidsReviewedData {
  acceptedCids: GenericCountItem;
  rejectedCids: GenericCountItem;
  totalCidsReviewed: GenericCountItem;
  filteredCidsPercentage: GenericCountItem;
  unfilteredCidsPercentage: GenericCountItem;
}

export enum ComplaintType {
  Copyright = 'Copyright Infringement',
  Inappropriate = 'Inappropriate Content',
  Other = 'Other',
}

export interface TypeStatsItemData {
  type: ComplaintType;
  count: number;
  percentageCount: number;
}

export interface ComplaintsData {
  submittedComplaintsCount: GenericCountItem;
  reviewedComplaintsCount: GenericCountItem;
  complaintsCount: GenericCountItem;
  complaintsTypes: {
    IPFS: Array<TypeStatsItemData>;
    Filecoin: Array<TypeStatsItemData>;
    totalCount: Array<TypeStatsItemData>;
  };
}

export interface ComplainantsData {
  totalComplainants: GenericCountItem;
  complainantCountries: number;
}

export interface AssessorsData {
  totalAssessors: GenericCountItem;
}

interface CountryItem {
  id: string;
  label: string;
  count: number;
  percentageCount: number;
}

export interface CountriesData {
  totalCountries: string | number;
  topFiveCountries?: TopFiveCountries;
}

export type FileTypesObject = Array<{
  count: number;
  percentageCount: number;
  fileType: FileType;
}>;

export interface IComplaintStats {
  //General Stats
  cidsReviewedObject: CidsReviewedData | undefined;
  complaintsObject: ComplaintsData | undefined;
  complainantsObject: ComplainantsData | undefined;
  assessorsObject: AssessorsData | undefined;
  countriesObject: CountriesData | undefined;
  fileTypesObject:
    | {
        [key in NetworkType]: FileTypesObject;
      }
    | undefined;
  loadingGeneral: boolean;

  //Stacked Line Data
  cidsReviewedPerDay: Array<GenericPerDayItemResponse> | undefined;
  complaintsPerDay: Array<GenericPerDayItemResponse> | undefined;
  complainantsPerDay: Array<GenericPerDayItemResponse> | undefined;
  assessorsPerDay: Array<GenericPerDayItemResponse> | undefined;
  loadingStacked: boolean;
}

export type CountryInfo = {
  count: number;
  percentageCount: number;
  label: string;
  id: string;
};

export type TopFiveCountries = {
  [key in NetworkType]?: Array<CountryInfo>;
} & {
  totalCount?: Array<CountryInfo>;
};
