export const customStyles = {
  control: (provided: any) => {
    return {
      ...provided,
      backgroundColor: '#2F363D',
      border: 0,
      minHeight: '32px',
      outline: '1px solid #444D56',
      maxWidth: '300px',
    };
  },
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    marginRight: '8px',
  }),
  input: (provided: any) => ({
    ...provided,
    color: '#FFF',
  }),
  menuList: (provided: any) => ({
    ...provided,
    backgroundColor: '#2F363D',
    borderRadius: '6px',
    width: 'fit-content',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#2F363D',
    borderRadius: '6px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    'div:first-of-type': { width: 'fit-content !important' },
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: '#24292E',
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: '#FFF',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    backgroundColor: '#24292E',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: '#24292E',
      color: '#FFF',
    },
  }),
  option: (provided: any) => ({
    ...provided,
    backgroundColor: '#2F363D',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#444D56',
    },
  }),
};
