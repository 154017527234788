import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import { ComplaintType } from 'models/Modules.models';
import { useLayoutEffect } from 'react';

interface PieChartProps {
  name: string;
  data: Array<{
    type: ComplaintType;
    count: number;
  }>;
}
export function PieChart(props: PieChartProps) {
  useLayoutEffect(() => {
    const data = [...props.data];
    am5.addLicense('AM5C333440217');
    const root = am5.Root.new(`pie-chart-${props.name}`);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
      })
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        alignLabels: true,
        calculateAggregates: true,
        valueField: 'count',
        categoryField: 'type',
        tooltip: am5.Tooltip.new(root, {
          scale: 0.5,
          labelText: '[#ffffff]{count}%',
        }),
        radius: 80,
      })
    );

    series
      .get('colors')
      ?.set('colors', [
        am5.color(0xe36209),
        am5.color(0xf66a0a),
        am5.color(0xfb8532),
        am5.color(0xffab70),
        am5.color(0xffd1ac),
      ]);

    series.slices.template.setAll({
      strokeOpacity: 0,
      shadowColor: am5.color(0x000000),
      shadowBlur: 15,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      shadowOpacity: 0.2,
    });

    //series.labelsContainer.set('paddingTop', 30);

    /*series.slices.template.adapters.add('radius', function (radius, target) {
      let dataItem = target.dataItem;
      let high = series.getPrivate('valueHigh');

      if (dataItem) {
        // @ts-ignore
        let value = target.dataItem.get('valueWorking', 0);
        // @ts-ignore
        // @ts-ignore
        return (radius * value) / high;
      }
      return radius;
    });*/

    //series.labels.template.set('forceHidden', true);

    series.data.setAll(data);
    series.labels.template.setAll({
      text: '{type}',
      fill: am5.color(0xffffff),
      fontSize: 14,
      scale: 1,
      oversizedBehavior: 'wrap',
      maxWidth: 110,
    });
    series.ticks.template.setAll({
      stroke: am5.color(0xffffff),
      strokeOpacity: 0.5,
      strokeWidth: 1,
    });

    // series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  });

  return (
    <div
      id={`pie-chart-${props.name}`}
      style={{ width: '100%', height: '180px' }}
    ></div>
  );
}
