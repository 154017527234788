import axios from 'axios';
import {
  GeneralStatsPayload,
  GeneralStatsResponse,
  GenericPerDayItemResponse,
} from 'models/GeneralStats';
import { Filter, PublicRelatedComplaints } from 'models/IndividualRecord';
import {
  Complaint,
  PublicComplaintsPayload,
  PublicComplaintsResponse,
  PublicComplaintsDailyStatsPayload,
  AdjustedFilterListsComplaint,
} from 'models/PublicComplaints';
import { serverUri } from '../config';

const ApiService = {
  fetchGeneralStats: async (
    generalStatsPayload?: GeneralStatsPayload
  ): Promise<GeneralStatsResponse> => {
    const response = await axios({
      method: 'get',
      url: `${serverUri()}/complaints/stats`,
      params: generalStatsPayload,
    });

    return response.data;
  },

  cidsReviewedPerDay: async (
    generalStatsPayload?: GeneralStatsPayload
  ): Promise<Array<GenericPerDayItemResponse>> => {
    const response = await axios({
      method: 'get',
      url: `${serverUri()}/complaints/stats/infringement`,
      params: generalStatsPayload,
    });

    return response.data;
  },

  complaintsPerDay: async (
    generalStatsPayload?: GeneralStatsPayload
  ): Promise<Array<GenericPerDayItemResponse>> => {
    const response = await axios({
      method: 'get',
      url: `${serverUri()}/complaints/stats/complaint`,
      params: generalStatsPayload,
    });

    return response.data;
  },

  complainantsPerDay: async (
    generalStatsPayload?: GeneralStatsPayload
  ): Promise<Array<GenericPerDayItemResponse>> => {
    const response = await axios({
      method: 'get',
      url: `${serverUri()}/complaints/stats/complainant`,
      params: generalStatsPayload,
    });

    return response.data;
  },

  assessorsPerDay: async (
    generalStatsPayload?: GeneralStatsPayload
  ): Promise<Array<GenericPerDayItemResponse>> => {
    const response = await axios({
      method: 'get',
      url: `${serverUri()}/complaints/stats/assessor`,
      params: generalStatsPayload,
    });

    return response.data;
  },

  getAssessors: async () => {
    const response = await axios({
      method: 'get',
      url: `${serverUri()}/assessor`,
    });

    return response.data;
  },

  getPublicComplaints: async (
    publicComplaintsPayload?: PublicComplaintsPayload
  ): Promise<PublicComplaintsResponse> => {
    const response = await axios({
      method: 'get',
      url: `${serverUri()}/complaints/public`,
      params: publicComplaintsPayload,
    });

    return response.data;
  },

  getComplaintsDailyStats: async (
    publicComplaintsPayload?: PublicComplaintsDailyStatsPayload
  ): Promise<Array<{ date: string; value: number }>> => {
    const response = await axios({
      method: 'get',
      url: `${serverUri()}/complaints/stats/dailycomplaints`,
      params: publicComplaintsPayload,
    });

    return response.data;
  },

  getPublicComplaint: async (
    id: string
  ): Promise<AdjustedFilterListsComplaint> => {
    const response = await axios({
      method: 'get',
      url: `${serverUri()}/complaints/public/${id}`,
    });

    return response.data;
  },

  getPublicRelatedComplaints: async (
    id: string
  ): Promise<PublicRelatedComplaints> => {
    const response = await axios({
      method: 'get',
      url: `${serverUri()}/complaints/public/${id}/related`,
    });

    return response.data;
  },

  getRelatedFilters: async (id: string): Promise<Array<Filter>> => {
    const response = await axios({
      method: 'get',
      url: `${serverUri()}/complaints/public/${id}/related_filters`,
    });

    return response.data;
  },
};

export default ApiService;
