export const serverUri = (): string => {
  const environment = process.env.NODE_ENV;
  switch (environment) {
    case 'development':
      return process.env.REACT_APP_HOST || 'http://172.30.1.3:3030';
    case 'production':
      return 'https://backend.bitscreen.co';
    case 'test':
      return 'http://localhost:3030';
    default:
      return 'https://backend.bitscreen.co';
  }
};

export const captchaKey = (): string => {
  const environment = process.env.NODE_ENV;
  switch (environment) {
    case 'development':
      return '6LetsCQbAAAAAJZX2f6rugsYlyQKbDZdN5Ccp-TE';
    case 'production':
      return '6LcChDUiAAAAAJzW4OaTRvzwhhjXx8QQp4HDc7hs';
    default:
      return 'https://bxn.keyko.rocks';
  }
};

export const bitscreenUri = (): string => {
  const environment = process.env.NODE_ENV;
  switch (environment) {
    case 'development':
      return 'http://localhost:13000';
    case 'production':
      return 'https://app.bitscreen.co';
    default:
      return 'https://app.bitscreen.co';
  }
};

export const rodeoUri = (): string => {
  const environment = process.env.NODE_ENV;
  switch (environment) {
    case 'development':
      return 'http://localhost:14000';
    case 'production':
      return 'https://rodeo.bitscreen.co';
    default:
      return 'https://rodeo.bitscreen.co';
  }
};
