import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { Record } from 'models/Records';
import * as React from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './TableContent.css';
import { Data, Order } from '../AssessorDetails';
import MUITooltip from '@mui/material/Tooltip';
import { redExclamationMark } from '../../../resources/icons';
import { RecordsFilter } from 'modules/Records/TableRecords/TableRecords';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: '_id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: true,
    label: 'Category',
  },
  {
    id: 'fullName',
    numeric: false,
    disablePadding: true,
    label: 'Complainant',
  },
  {
    id: 'assessor',
    numeric: false,
    disablePadding: true,
    label: 'Assessed by',
  },
  {
    id: 'geoScope',
    numeric: false,
    disablePadding: true,
    label: 'Scope',
  },
  {
    id: 'submittedOn',
    numeric: false,
    disablePadding: true,
    label: 'Published on',
  },
];

const determineCellWidth = (cellId: keyof Data) => {
  switch (cellId) {
    case '_id':
      return '12%';

    case 'category':
      return '14%';

    case 'fullName':
      return '26%';

    case 'assessor':
      return '22%';

    case 'geoScope':
      return '14%';

    case 'submittedOn':
      return '12%';
  }
};

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      let propertyName: keyof Data;
      switch (property) {
        case 'category':
          propertyName = 'type' as keyof Data;
          break;

        default:
          propertyName = property as keyof Data;
          break;
      }

      onRequestSort(event, propertyName);
    };

  return (
    <TableHead className="table-content-header">
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.id === '_id') {
            return (
              <TableCell
                className="fw-700 fs-16 lh-24 table-content-id-column"
                key={headCell.id}
                align="left"
                sortDirection={orderBy === headCell.id ? order : false}
                width={determineCellWidth(headCell.id)}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  color="white"
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                className="fw-700 fs-16 lh-24"
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
                width={determineCellWidth(headCell.id)}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}

interface TableContentPaginationProps {
  page: number;
  onPageChange: (page: number) => void;
  totalPages: number;
  resultsPerPage: number;
  setResultsPerPage: React.Dispatch<React.SetStateAction<number>>;
}

function TableContentPagination(props: TableContentPaginationProps) {
  const { resultsPerPage, setResultsPerPage, page, onPageChange, totalPages } =
    props;

  return (
    <div className="table-content-pagination-container px-48 py-24 w-full d-flex align-items-center justify-content-between">
      <div className="pagination-display-results">
        <div className="table-dropdown-container d-flex align-items-center">
          <div className="mr-4px text-white">Display</div>
          <div className="table-content-display-dropdown mr-4px">
            <Dropdown className="">
              <Dropdown.Toggle className="toggle-dropdown-button fs-14 lh-20 fw-600 px-12 py-6">
                {resultsPerPage}
              </Dropdown.Toggle>

              <Dropdown.Menu className="toggle-menu">
                <Dropdown.Item
                  onClick={() => setResultsPerPage(15)}
                  key="results_15"
                >
                  <div className="custom-menu-item menu-item-created">15</div>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setResultsPerPage(20)}
                  key="results_20"
                >
                  <div className="custom-menu-item menu-item-created">20</div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="text-white">results</div>
        </div>
      </div>
      <div className="pagination-previous-next d-flex">
        <div
          onClick={() => {
            if (page === 1) return;
            onPageChange(page - 1);
          }}
          className="generic-button transparent-button mr-12"
        >
          Previous
        </div>
        <div
          className="generic-button transparent-button"
          onClick={() => {
            if (totalPages === page) return;
            onPageChange(page + 1);
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
}

interface TableContentProps {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  order: Order;
  setOrder: React.Dispatch<React.SetStateAction<Order>>;
  orderBy: string;
  setOrderBy: React.Dispatch<React.SetStateAction<keyof Data>>;
  totalPages: number;
  setResultsPerPage: React.Dispatch<React.SetStateAction<number>>;
  resultsPerPage: number;
  records: Record[];
  recordsFilter: RecordsFilter;
  recordsIdsArray: Array<string>;
}

export default function TableContent(props: TableContentProps) {
  const navigate = useNavigate();

  const { records } = props;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = props.orderBy === property && props.order === 'asc';
    props.setOrder(isAsc ? 'desc' : 'asc');
    props.setOrderBy(property);
  };

  const handleChangePage = (newPage: number) => {
    props.setPage(newPage);
  };

  const emptyRows =
    records.length === 0
      ? props.resultsPerPage - 2
      : props.resultsPerPage - records.length;

  const mapRowScope = (scope: Array<string>) => {
    if (scope.length === 1)
      return {
        shownLabel: scope[0],
        remainingScope: [],
      };

    const scopeHasGlobal = scope.find((e) => e === 'Global');

    if (scopeHasGlobal && scopeHasGlobal.length)
      return {
        shownLabel: 'Global',
        remainingScope: scope.filter((e) => e !== 'Global'),
      };
    else {
      const sortedScope = [...scope];
      sortedScope.sort((a, b) => {
        const keyA = a;
        const keyB = b;

        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
      return {
        shownLabel: sortedScope[0],
        remainingScope: scope.slice(1),
      };
    }
  };

  return (
    <Box className="table-content-box" sx={{ width: '100%' }}>
      <Paper className="table-content-paper" sx={{ width: '100%' }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={props.order}
              orderBy={props.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={records.length}
            />
            <TableBody>
              {records.length === 0 && (
                <TableRow
                  style={{
                    height: 44 * 2,
                  }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                >
                  <TableCell
                    colSpan={12}
                    className="no-data-found fs-24 fw-700"
                    component="th"
                    scope="row"
                    padding="none"
                  >
                    No data found for the requested query.
                  </TableCell>
                </TableRow>
              )}
              {records.length > 0 &&
                records.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const scopeObject = mapRowScope(row.geoScope);
                  return (
                    <TableRow
                      hover
                      onClick={() => {
                        navigate(`/records/${row.id}`, {
                          replace: true,
                          state: {
                            recordsFilter: props.recordsFilter,
                            recordsIdsArray: props.recordsIdsArray,
                          },
                        });
                      }}
                      tabIndex={-1}
                      key={row.id}
                      style={{
                        height: 44,
                      }}
                      className={`${
                        index % 2 === 1 ? 'alternative-row-color' : ''
                      } c-pointer`}
                    >
                      <TableCell
                        key={labelId + '_id'}
                        id={labelId}
                        scope="row"
                        padding="normal"
                        className="pr-2 table-content-id-column"
                        width={determineCellWidth('_id')}
                      >
                        {row.id}
                        {row.isSpam && (
                          <MUITooltip arrow title="Marked as spam by Assessor">
                            <img
                              alt="attention symbol"
                              src={redExclamationMark}
                              style={{ marginLeft: '20px' }}
                            />
                          </MUITooltip>
                        )}
                      </TableCell>
                      <TableCell
                        key={labelId + '_cat'}
                        padding="none"
                        align="left"
                        width={determineCellWidth('category')}
                      >
                        {row.category}
                      </TableCell>
                      <TableCell
                        key={labelId + '_name'}
                        padding="none"
                        align="left"
                        width={determineCellWidth('fullName')}
                      >
                        <div className="d-flex flex-row">
                          {row.fullName} {row.companyName && '-'}
                          {row.companyName && row.companyName.length > 8
                            ? `${row.companyName.substring(0, 8)}...`
                            : row.companyName}
                        </div>
                      </TableCell>
                      <TableCell
                        key={labelId + '_assessor'}
                        padding="none"
                        align="left"
                        width={determineCellWidth('assessor')}
                      >
                        {row.assessor}
                      </TableCell>
                      <TableCell
                        key={labelId + '_scope'}
                        padding="none"
                        align="left"
                        width={determineCellWidth('geoScope')}
                      >
                        {scopeObject.shownLabel}
                        {scopeObject.remainingScope.length !== 0 && (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip>
                                {scopeObject.remainingScope.map((e, index) => {
                                  if (
                                    index !==
                                    scopeObject.remainingScope.length - 1
                                  )
                                    return <span>{e}, </span>;
                                  else return <span>{e}</span>;
                                })}
                              </Tooltip>
                            }
                          >
                            <span className="bullet additional-info-bullet ml-4px">
                              +{scopeObject.remainingScope.length}
                            </span>
                          </OverlayTrigger>
                        )}
                      </TableCell>
                      <TableCell
                        key={labelId + '_submittedOn'}
                        padding="none"
                        align="left"
                        width={determineCellWidth('submittedOn')}
                      >
                        {row.submittedOrResolvedOn}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 44 * emptyRows,
                  }}
                >
                  <TableCell
                    padding="none"
                    className="empty-table-space"
                    colSpan={12}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContentPagination
          onPageChange={handleChangePage}
          page={props.page}
          totalPages={props.totalPages}
          setResultsPerPage={props.setResultsPerPage}
          resultsPerPage={props.resultsPerPage}
        />
      </Paper>
    </Box>
  );
}
