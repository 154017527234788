import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import './Assessors.css';
import { AdditionalResources } from 'modules/Common/AdditionalResources/AdditionalResources';
import ApiService from '../../services/api.service';
import { Assessor } from '../../models/Assessor';

export const Assessors = (): JSX.Element => {
  const [assessors, setAssessors] = useState<Assessor[] | undefined>(undefined);

  useEffect(() => {
    const getAssessors = async () => {
      const fetchedAssessors = await ApiService.getAssessors();
      setAssessors(fetchedAssessors.assessors);
    };
    getAssessors();
    document.title = 'Looking Glass - Assessors';
  }, []);

  return (
    <div className="assessors-container text-white">
      <div className="assessors-info px-48 pt-48">
        <div className="assessors-info-title mb-24 fw-700">Assessors</div>
        <div className="assessors-info-body d-flex">
          <div className="assessors-info-body-description flex-1">
            Placerat in odio pellentesque eros. Gravida velit urna nullam
            tincidunt tempus nulla porta tincidunt posuere. Aliquam auctor
            auctor nisl, viverra. Massa sed id euismod eget. Sed sollicitudin
            mauris nunc at amet enim. Tempor ultrices enim gravida aliquam
            tristique pulvinar nulla nisl. Venenatis faucibus enim, sociis at
            condimentum vitae varius feugiat dolor. Nulla cras vestibulum dui
            diam vitae, mi consectetur non. Non volutpat adipiscing vulputate
            leo tortor fames lorem commodo. Eu blandit malesuada senectus id
            euismod tincidunt enim tempus vel. Id pellentesque proin lectus in
            sollicitudin pretium, risus accumsan elementum.
          </div>
          <AdditionalResources />
        </div>
      </div>
      <div className="assessor-list">
        {assessors
          ? assessors.map((assessor) => (
              <div className="assessor-list__card" key={assessor.id}>
                <p className="assessor-list__assessor-name">
                  {assessor.businessName}
                </p>
                <p className="assessor-list__reports-assessed">
                  {assessor.resolvedCount} reports assessed
                </p>
                <p className="assessor-list__date-registered">
                  Registered on {dayjs(assessor.created).format('MM/DD/YYYY')}
                </p>
                <div className="d-flex justify-content-between mb-0">
                  <a href={`/assessors/${assessor.id}`}>View all reports →</a>
                  {assessor.deletedAt && (
                    <div className="deactivated-assessor">Deactivated</div>
                  )}
                </div>
              </div>
            ))
          : `Loading`}
      </div>
    </div>
  );
};
