import './RecordsGraphs.css';
import { Bar } from 'common/Charts/Bar';
import { IDailyStatsItem } from './TableRecords';

export const RecordsGraphs = (props: any): JSX.Element => {
  const { stats } = props;

  const numReports = stats.reduce((p: number, c: IDailyStatsItem) => {
    p += c.value;
    return p;
  }, 0);

  return (
    <div className="records-graphs-container assessor-detail-header d-flex px-48 py-24">
      <div className="records-graphs-left align-items-center text-white">
        <div className="records-graphs-reports-title">
          Reports in selected set
        </div>
        <div className="records-graphs-reports-num">{numReports}</div>
        <div className="records-graphs-reports-info">
          (Adjust filters for additional records)
        </div>
      </div>
      <div className="records-graphs-right">
        <Bar name="records-graphs" data={stats} />
      </div>
    </div>
  );
};
