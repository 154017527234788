import { PieChart } from 'common/Charts/Pie';
import { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import countryList from 'react-select-country-list';
import { DonutChart } from 'common/Charts/Donut';
import { SemiDonutChart } from 'common/Charts/SemiDonut';
import { StackedLineGraph } from 'common/Charts/StackedLine';
import { WorldMap } from 'common/Charts/WorldMap';
import './Dashboard.css';
import { TimePeriodEnum } from 'modules/Records/TableRecords/TableRecords';
import { useComplaintStats } from 'library/hooks/useStats';
import { useNavigate } from 'react-router-dom';
import { AdditionalResources } from 'modules/Common/AdditionalResources/AdditionalResources';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { DropdownIndicator } from './DropdownIndicator/DropdownIndicator';
import { Region } from '../../models/GeneralStats';
import { customStyles } from './reactSelectCustomStyles';
import { NetworkType } from 'models/Common';

const animatedComponents = makeAnimated();

export const Dashboard = (props: any): JSX.Element => {
  const [networkType, setNetworkType] = useState<NetworkType>(
    NetworkType.Filecoin
  );
  const [region, setRegion] = useState<Region[]>([
    {
      label: 'Global',
      value: 'Global',
    },
  ]);
  const [timePeriod, setTimePeriod] = useState({
    label: TimePeriodEnum.months6,
    value: 180,
  });

  const {
    //General Stats Data
    cidsReviewedObject,
    complaintsObject,
    complainantsObject,
    assessorsObject,
    countriesObject,
    fileTypesObject,

    //Stacked Line Data
    cidsReviewedPerDay,
    complaintsPerDay,
    complainantsPerDay,
  } = useComplaintStats(timePeriod.value, region);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Looking Glass - Dashboard';
  }, []);

  return (
    <div className="dashboard-container">
      <div className="dashboard-header px-48 py-16 d-flex text-white fs-14 lh-20 fw-600">
        <div className="dashboard-header-dropdown-container mr-24 d-flex align-items-center">
          <div className="dashboard-header-dropdown-text mr-4px">Network:</div>
          <div className="dashboard-header-dropdown-input">
            <Dropdown className="">
              <Dropdown.Toggle className="toggle-dropdown-button fs-14 lh-20 fw-600 py-6 px-16">
                {networkType}
              </Dropdown.Toggle>

              <Dropdown.Menu className="toggle-menu">
                <Dropdown.Item onClick={() => setNetworkType(NetworkType.IPFS)}>
                  <div className="custom-menu-item menu-item-created">IPFS</div>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setNetworkType(NetworkType.Filecoin)}
                >
                  <div className="custom-menu-item menu-item-created">
                    Filecoin
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="dashboard-header-dropdown-container mr-24 d-flex align-items-center">
          <div className="dashboard-header-dropdown-text mr-4px">Region:</div>
          <Select
            components={{ ...animatedComponents, DropdownIndicator }}
            isMulti
            onChange={(e: any) => {
              setRegion(e);
            }}
            options={countryList().getData()}
            placeholder="Filter by region..."
            styles={customStyles}
          />
        </div>
        <div className="dashboard-header-dropdown-container mr-24 d-flex align-items-center">
          <div className="dashboard-header-dropdown-text mr-4px">
            Time period:
          </div>
          <div className="dashboard-header-dropdown-input">
            <Dropdown className="">
              <Dropdown.Toggle className="toggle-dropdown-button fs-14 lh-20 fw-600 py-6 px-16">
                {timePeriod.label}
              </Dropdown.Toggle>

              <Dropdown.Menu className="toggle-menu">
                <Dropdown.Item
                  onClick={() => {
                    setTimePeriod({
                      label: TimePeriodEnum.days7,
                      value: 7,
                    });
                  }}
                >
                  <div className="custom-menu-item menu-item-created">
                    Last 7 days
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setTimePeriod({
                      label: TimePeriodEnum.days30,
                      value: 30,
                    });
                  }}
                >
                  <div className="custom-menu-item menu-item-created">
                    Last 30 days
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setTimePeriod({
                      label: TimePeriodEnum.months3,
                      value: 90,
                    });
                  }}
                >
                  <div className="custom-menu-item menu-item-created">
                    Last 3 months
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setTimePeriod({
                      label: TimePeriodEnum.months6,
                      value: 180,
                    });
                  }}
                >
                  <div className="custom-menu-item menu-item-created">
                    Last 6 months
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setTimePeriod({
                      label: TimePeriodEnum.year,
                      value: 365,
                    });
                  }}
                >
                  <div className="custom-menu-item menu-item-created">
                    Last year
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="dashboard-content text-white">
        <div className="dashboard-content-first px-48 pt-48">
          <div className="dashboard-content-first-title mb-24 fw-700">
            Filecoin Transparency Center
          </div>
          <div className="dashboard-content-first-body d-flex">
            <div className="dashboard-content-first-body-description flex-1">
              <p>
                Welcome to Looking Glass, the Filecoin network's dedicated
                transparency hub. Our mission is to promote accountability and
                openness by aggregating public data related to legal complaints,
                content removal requests, and content filtering within the
                Filecoin ecosystem.
              </p>
              <p>
                Looking Glass empowers rights holders and those potentially
                affected by content hosted on the Filecoin network to submit
                complaints via our portal. Expert assessors carefully review
                each complaint, and where appropriate, include the relevant CIDs
                in filter lists maintained independently by assessors. Storage
                providers may then subscribe to these lists to effectively
                filter out known CIDs, ensuring a secure and trustworthy network
                for all users.
              </p>
              <p>
                Records of complaints and any actions taken by assessors are
                made public via this transparency hub.
              </p>
            </div>
            <AdditionalResources />
          </div>
        </div>
        <div className="home-second-page-lower-section dashboard-first-statistics text-white d-flex px-24">
          <div className="home-second-page-lower-right flex-2 mr-24">
            <div className="home-second-page-lower-right-upper container-box mb-24 p-24">
              <div className="second-lower-right-upper-header d-flex justify-content-between mb-48">
                <div className="second-lower-right-upper-header-title fs-20 lh-28 fw-700">
                  Complaints{' '}
                  <span className="selected-period-filter-text light-grey-text fw-400 fs-20 lh-28">
                    ({timePeriod.label})
                  </span>
                </div>
              </div>
              <div className="second-lower-right-upper-content d-flex">
                <div className="second-lower-right-upper-content-left d-flex flex-column flex-1">
                  <div className="second-lower-right-upper-content-left-upper d-flex align-items-center">
                    <div className="second-lower-right-upper-content-left-text flex-1">
                      {complaintsObject?.complaintsCount[networkType] ?? 0}
                    </div>
                    <div className="second-lower-right-upper-content-left-statistics">
                      <div className="d-flex flex-column align-items-center">
                        {console.log(complaintsPerDay)}
                        {complaintsPerDay && (
                          <StackedLineGraph
                            name="second-lower-right-upper-content-left-statistics-stacked-first-filecoin"
                            height={75}
                            width={160}
                            data={complaintsPerDay.map((e) => ({
                              count: e[networkType],
                              date: e.date,
                            }))}
                            centerY={0}
                            onClickConfig={{
                              orderBy: 'submittedOn',
                              order: 'desc',
                              timePeriod: timePeriod.label,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="second-lower-right-upper-content-left-lower d-flex flex-column fs-20 lh-28">
                    <div className="second-lower-right-upper-content-left-received flex-1 mb-8">
                      {complaintsObject?.submittedComplaintsCount[networkType]}{' '}
                      <span className="fw-600">submitted</span>
                    </div>
                    <div className="second-lower-right-upper-content-left-assessed">
                      {complaintsObject?.reviewedComplaintsCount[networkType]}{' '}
                      <span className="fw-600">assessed</span>
                    </div>
                  </div>
                </div>
                <div className="second-lower-right-upper-content-right flex-1 d-flex justify-content-center align-items-center fs-36 fw-600">
                  {complaintsObject &&
                    !!complaintsObject.complaintsTypes[networkType] &&
                    complaintsObject.complaintsTypes[networkType].length !==
                      0 && (
                      <PieChart
                        data={complaintsObject.complaintsTypes[networkType].map(
                          (e) => ({
                            ...e,
                            type: e.type,
                            count: e.percentageCount,
                          })
                        )}
                        name="dashboard-filecoin"
                      />
                    )}
                </div>
              </div>
            </div>
            <div className="home-second-page-lower-right-lower d-flex">
              <div className="home-second-page-lower-right-lower-left flex-1 mr-24 container-box small-container-box pt-24 pl-24 pr-24">
                <div className="second-lower-right-upper-header d-flex justify-content-between mb-4px">
                  <div className="fs-20 lh-28 fw-700">
                    Complainants{' '}
                    <span className="selected-period-filter-text light-grey-text fw-400 fs-20 lh-28">
                      ({timePeriod.label})
                    </span>
                  </div>
                  {/* <div className="header-box-number">+123.45 u</div> */}
                </div>
                <div className="d-flex align-items-center">
                  <div className="second-lower-right-upper-content-left-text flex-1">
                    {complainantsObject?.totalComplainants[networkType] || 0}
                  </div>
                  {complainantsPerDay && (
                    <div className="second-lower-right-upper-content-left-statistics">
                      <div className="d-flex flex-column align-items-center">
                        <StackedLineGraph
                          name="second-lower-right-upper-content-left-statistics-stacked-second-filecoin"
                          height={75}
                          width={160}
                          data={complainantsPerDay.map((e) => ({
                            count: e[networkType],
                            date: e.date,
                          }))}
                          centerY={0}
                          onClickConfig={{
                            orderBy: 'fullName',
                            order: 'asc',
                            timePeriod: timePeriod.label,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="home-second-page-lower-right-lower-right flex-1 container-box small-container-box pt-24 pl-24 pr-24">
                <div className="second-lower-right-upper-header d-flex justify-content-between mb-4px">
                  <div className="fs-20 lh-28 fw-700">
                    Assessors{' '}
                    <span className="selected-period-filter-text light-grey-text fw-400 fs-20 lh-28">
                      ({timePeriod.label})
                    </span>
                  </div>
                  {/* <div className="header-box-number">+123.45 u</div> */}
                </div>
                <div className="d-flex align-items-center">
                  <div className="second-lower-right-upper-content-left-text flex-1">
                    {assessorsObject?.totalAssessors[networkType] ?? 0}
                  </div>
                  <div className="second-lower-right-upper-content-left-statistics">
                    <div className="d-flex flex-column align-items-center">
                      <span
                        className="link-design cursor-pointer orange-text"
                        onClick={() => navigate('/assessors')}
                      >
                        See All -&gt;
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-second-page-lower-left flex-1 container-box d-flex flex-column p-24">
            <div className="second-lower-left-header d-flex justify-content-between mb-48">
              <div className="second-lower-left-header-title fs-20 lh-28 fw-700">
                CIDs reviewed{' '}
                <span className="selected-period-filter-text light-grey-text fw-400 fs-20 lh-28">
                  ({timePeriod.label})
                </span>
              </div>
              {/* <div className="second-lower-left-header-number header-box-number fs-12 lh-16">
                +123.45 u
              </div> */}
            </div>
            <div className="second-lower-left-content d-flex flex-column">
              <div className="second-lower-left-content-first d-flex justify-content-between align-items-center mb-24">
                <div className="second-lower-left-content-first-text flex-1">
                  {cidsReviewedObject?.totalCidsReviewed[networkType]}
                </div>
                {cidsReviewedPerDay && (
                  <div className="second-lower-left-content-first-statistics">
                    <div className="d-flex flex-column align-items-center">
                      <StackedLineGraph
                        name="second-lower-left-content-first-statistics-stacked-filecoin"
                        data={cidsReviewedPerDay.map((e) => ({
                          count: e[networkType],
                          date: e.date,
                        }))}
                        width={160}
                        centerY={0}
                        height={75}
                        onClickConfig={{
                          orderBy: 'submittedOn',
                          order: 'desc',
                          timePeriod: timePeriod.label,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="second-lower-left-content-second d-flex flex-column">
                <div className="second-lower-left-content-second-text mb-8">
                  {cidsReviewedObject?.acceptedCids[networkType]}{' '}
                  <span className="fw-600">accepted</span>
                </div>
                <div className="second-lower-left-content-second-text">
                  {cidsReviewedObject?.rejectedCids[networkType]}{' '}
                  <span className="fw-600">rejected</span>
                </div>
              </div>
              <div className="second-lower-left-content-third d-flex flex-column justify-content-center align-items-center">
                {cidsReviewedObject &&
                  (cidsReviewedObject.filteredCidsPercentage[networkType] !==
                    0 ||
                    cidsReviewedObject.unfilteredCidsPercentage[networkType] !==
                      0) && (
                    <SemiDonutChart
                      data={[
                        {
                          type: 'Filtered',
                          count:
                            cidsReviewedObject.filteredCidsPercentage[
                              networkType
                            ],
                        },
                        {
                          type: 'Unfiltered',
                          count:
                            cidsReviewedObject.unfilteredCidsPercentage[
                              networkType
                            ],
                        },
                      ]}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="filecoin-section-container d-flex flex-column px-24">
          <div className="dashboard-content-second px-24">
            {networkType === NetworkType.Filecoin ? (
              <div className="dashboard-content-second-title filecoin-title mb-48 fw-700">
                Filecoin network trends
              </div>
            ) : (
              <div className="dashboard-content-second-title ipfs-title mb-48 fw-700">
                IPFS network trends
              </div>
            )}
          </div>
          <div className="dashboard-content-third mb-24">
            {countriesObject && countriesObject.topFiveCountries && (
              <div className="dashboard-content-third-box container-box p-24 d-flex">
                <div className="dashboard-content-third-statistics d-flex flex-column flex-1">
                  <div className="dashboard-content-third-statistics-title mb-8 fs-20 lh-28 fw-700">
                    Top 5 countries{' '}
                    <span className="light-grey-text fw-400">
                      (by complaints)
                    </span>
                  </div>
                  <div className="dashboard-content-third-statistics-description fs-16 lh-24 fw-400 mb-40">
                    Below are the top five countries ranked according to the
                    geographic scope of the complaint, as indicated at the time
                    of filing.
                  </div>
                  <div className="dashboard-content-third-statistics-content d-flex">
                    <div className="d-flex flex-column">
                      <div className="d-flex mr-40 flex-1">
                        <div className="dashboard-content-third-statistics-content-list">
                          {countriesObject.topFiveCountries[networkType] &&
                            countriesObject.topFiveCountries[
                              networkType
                            ]!.filter((e) => e.count).map((e, index) => {
                              return (
                                <div className="fw-700 mb-16 d-flex align-items-center">
                                  <div
                                    className="bullet country-bullet mr-8"
                                    style={{
                                      backgroundColor: [
                                        '#c8e1ff',
                                        '#0366d6',
                                        '#2188ff',
                                        '#044289',
                                        '#79b8ff',
                                      ][index],
                                    }}
                                  ></div>
                                  <span>{e.label}</span>
                                </div>
                              );
                            })}
                        </div>
                        <div className="dashboard-content-third-statistics-content-percentages">
                          {countriesObject.topFiveCountries[networkType] &&
                            countriesObject.topFiveCountries[
                              networkType
                            ]!.filter((e) => e.count).map((e) => {
                              return (
                                <div className="light-grey-text fw-400 mb-16">
                                  {e.percentageCount}%
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="selected-period-filter-text light-grey-text fs-20 lh-28">
                        ({timePeriod.label})
                      </div>
                    </div>
                    <div className="dashboard-content-third-statistics-content-donut d-flex justify-content-center align-items-center flex-1">
                      {countriesObject.topFiveCountries[networkType] && (
                        <DonutChart
                          name="dashboard-content-third-statistics-content-donut"
                          colors={[
                            0xc8e1ff, 0x0366d6, 0x2188ff, 0x044289, 0x79b8ff,
                          ]}
                          textColorWhite={true}
                          data={countriesObject.topFiveCountries[
                            networkType
                          ]!.filter((e) => e.count).map((e) => ({
                            count: e.percentageCount,
                          }))}
                          showPercentages={false}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="dashboard-content-third-map flex-15 d-flex justify-content-center align-items-center fs-34 fw-700">
                  {countriesObject &&
                    countriesObject.topFiveCountries[networkType] && (
                      <WorldMap
                        data={countriesObject.topFiveCountries[
                          networkType
                        ]!.filter((e) => e.count).map((e) => ({
                          id: e.id,
                        }))}
                        name="dashboard-filecoin"
                      ></WorldMap>
                    )}
                </div>
              </div>
            )}
          </div>
          <div className="dashboard-content-fourth d-flex">
            <div className="dashboard-content-fourth-complaints-category p-24 flex-1 container-box mr-24">
              {complaintsObject && complaintsObject.complaintsTypes && (
                <>
                  <div className="dashboard-content-fourth-complaints-category-title fs-20 lh-28 fw-700">
                    Complaints per category{' '}
                    <span className="selected-period-filter-text light-grey-text fw-400 fs-20 lh-28">
                      ({timePeriod.label})
                    </span>
                  </div>
                  <div className="dashboard-content-fourth-complaints-category-content d-flex fs-20 lh-28 fw-700">
                    <div className="dashboard-content-fourth-complaints-category-list d-flex flex-column justify-content-center flex-15">
                      {complaintsObject.complaintsTypes[networkType].map(
                        (e, index) => {
                          let colorHex;
                          switch (index) {
                            case 0:
                              colorHex = 'ffdf5d';
                              break;

                            case 1:
                              colorHex = 'ffd33d';
                              break;

                            case 2:
                              colorHex = 'fff5b1';
                              break;
                          }

                          return (
                            <div className="dashboard-content-fourth-complaints-category-list-row d-flex justify-content-between mb-16">
                              <div className="dashboard-content-fourth-complaints-category-type d-flex align-items-center fs-16 lh-20 fw-700">
                                <div
                                  style={{
                                    height: '12px',
                                    width: '12px',
                                    borderRadius: '50%',
                                    backgroundColor: `#${colorHex}`,
                                    marginRight: '8px',
                                  }}
                                />
                                {e.type}
                              </div>
                              <div className="dashboard-content-fourth-complaints-category-value fs-16 lh-20">
                                {e.count}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div className="dashboard-content-fourth-complaints-category-donut-chart d-flex flex-1 align-items-center justify-content-center">
                      <DonutChart
                        name="dashboard-content-fourth-complaints-category-donut"
                        colors={[0xffdf5d, 0xffd33d, 0xfff5b1]}
                        height={200}
                        data={complaintsObject.complaintsTypes[networkType].map(
                          (e) => ({
                            count: e.percentageCount,
                          })
                        )}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="dashboard-content-fourth-complaints-category p-24 flex-1 container-box">
              {fileTypesObject && (
                <>
                  <div className="dashboard-content-fourth-complaints-category-title fs-20 lh-28 fw-700">
                    Files distribution{' '}
                    <span className="selected-period-filter-text light-grey-text fw-400 fs-20 lh-28">
                      ({timePeriod.label})
                    </span>
                  </div>
                  <div className="dashboard-content-fourth-complaints-category-content d-flex fs-20 lh-28 fw-700">
                    <div className="dashboard-content-fourth-complaints-category-list d-flex flex-column justify-content-center flex-15">
                      {fileTypesObject[networkType].map((e, index) => {
                        let colorHex;
                        switch (index) {
                          case 0:
                            colorHex = '34d058';
                            break;

                          case 1:
                            colorHex = '28a745';
                            break;

                          case 2:
                            colorHex = '22863a';
                            break;

                          case 3:
                            colorHex = 'bef5cb';
                            break;

                          case 4:
                            colorHex = '85e89d';
                            break;
                        }
                        return (
                          <div className="dashboard-content-fourth-complaints-category-list-row d-flex justify-content-between mb-16">
                            <div className="dashboard-content-fourth-complaints-category-type d-flex align-items-center fs-16 lh-20 fw-700">
                              <div
                                style={{
                                  height: '12px',
                                  width: '12px',
                                  borderRadius: '50%',
                                  backgroundColor: `#${colorHex}`,
                                  marginRight: '8px',
                                }}
                              />
                              {e.fileType}
                            </div>
                            <div className="dashboard-content-fourth-complaints-category-value fs-16 lh-20">
                              {e.count}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="dashboard-content-fourth-complaints-category-donut-chart flex-1 d-flex align-items-center justify-content-center">
                      <DonutChart
                        name="dashboard-content-fourth-complaints-category-donut-second"
                        colors={[
                          0x34d058, 0x28a745, 0x22863a, 0xbef5cb, 0x85e89d,
                        ]}
                        height={200}
                        data={fileTypesObject[networkType].map((e) => ({
                          count: e.percentageCount,
                        }))}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
