import { ComplaintType } from 'models/Modules.models';
import { ComplaintTypeInt } from 'models/PublicComplaints';
import { IAssessorDetails } from 'models/Provider';
import { Record } from 'models/Records';
import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, Form, InputGroup } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import countryList from 'react-select-country-list';
import * as icons from 'resources/icons';
import ApiService from 'services/api.service';
import ProviderApiService from 'services/provider.service';
import { useDebounce } from 'usehooks-ts';
import TableContent from './TableContent/TableContent';
import { useNavigate, useParams } from 'react-router-dom';
import useCollapse from 'react-collapsed';
import './AssessorDetails.css';
import dayjs from 'dayjs';
import { Region } from '../../models/GeneralStats';
import Select from 'react-select';
import { DropdownIndicator } from '../Dashboard/DropdownIndicator/DropdownIndicator';
import { customStyles } from '../Dashboard/reactSelectCustomStyles';
import makeAnimated from 'react-select/animated';
import { ComplaintTypeFilter } from 'models/Complaint';
import { AntSwitch } from 'common/HTMLSwitchComponent';
import { NetworkType } from 'models/Common';
import { RecordsFilter } from 'modules/Records/TableRecords/TableRecords';

const animatedComponents = makeAnimated();

export interface Data {
  _id: string;
  category: string;
  companyName: string;
  fullName: string;
  assessor: string;
  geoScope: string;
  submittedOn: number;
}

export enum ScopeEnum {
  all = 'All',
}

export enum TimePeriodEnum {
  days7 = 'Last 7 days',
  days30 = 'Last 30 days',
  months3 = 'Last 3 months',
  months6 = 'Last 6 months',
  year = 'Last year',
}

export type Order = 'asc' | 'desc';

export const AssessorDetails = (props: any): JSX.Element => {
  const providerApiService = ProviderApiService();
  const params = useParams();
  const navigate = useNavigate();
  const assessor = params.id;
  const debounceTime = 600;
  const location = useLocation();
  const selectInputRef = useRef<any>();

  let filteringObjectFromState = (location.state as RecordsFilter) || {};

  const [complainantSearch, setComplainantSearch] = useState(
    filteringObjectFromState.complainantEmail || undefined
  );
  const [assessorSearch, setAssessorSearch] = useState(
    filteringObjectFromState.assessorId || undefined
  );

  const [assessorData, setAssessorData] = useState<IAssessorDetails>();

  const [page, setPage] = useState(1);
  const [recordsObject, setRecordsObject] = useState<{
    records: Record[];
    totalPages: number;
    totalCount: number;
  }>({
    records: [],
    totalPages: 1,
    totalCount: 0,
  });
  const [order, setOrder] = useState<Order>(
    filteringObjectFromState.order || 'desc'
  );

  const [orderBy, setOrderBy] = useState<keyof Data>(
    filteringObjectFromState.orderBy || '_id'
  );
  const [resultsPerPage, setResultsPerPage] = useState(
    filteringObjectFromState.resultsPerPage || 15
  );
  const [category, setCategory] = useState(
    filteringObjectFromState.category || ComplaintTypeFilter.All
  );
  const [network, setNetwork] = useState<NetworkType | 'All'>(
    filteringObjectFromState.network || 'All'
  );
  const [scope, setScope] = useState<Region[]>(
    filteringObjectFromState.scope || [
      {
        label: 'Global',
        value: 'Global',
      },
    ]
  );
  const [timePeriod, setTimePeriod] = useState(
    filteringObjectFromState.timePeriod || TimePeriodEnum.months6
  );

  const [query, setQuery] = useState<string>(
    filteringObjectFromState.query || ''
  );
  const debouncedQuery = useDebounce(query, debounceTime);

  const [showSpam, setShowSpam] = useState<boolean>(false);

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 500,
    defaultExpanded: true,
    collapsedHeight: 5,
  });

  useEffect(() => {
    const getAssessorData = async () => {
      const data = await providerApiService.getAssessorById(assessor);
      setAssessorData(data);
    };
    getAssessorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getPublicComplaints = async () => {
      let startingFrom;

      switch (timePeriod) {
        case TimePeriodEnum.days7:
          startingFrom = 7;
          break;

        case TimePeriodEnum.days30:
          startingFrom = 30;
          break;

        case TimePeriodEnum.months3:
          startingFrom = 90;
          break;

        case TimePeriodEnum.months6:
          startingFrom = 180;
          break;

        case TimePeriodEnum.year:
        default:
          startingFrom = 365;
          break;
      }

      let categoryFilter;

      switch (category) {
        case ComplaintTypeFilter.Copyright:
          categoryFilter = 1;
          break;

        case ComplaintTypeFilter.Inappropriate:
          categoryFilter = 2;
          break;

        case ComplaintTypeFilter.Other:
          categoryFilter = 3;
          break;
      }
      const data = await ApiService.getPublicComplaints({
        orderBy,
        orderDirection: order,
        category: categoryFilter,
        network: network === 'All' ? '' : network,
        regions: scope.map((sc) => sc.value),
        startingFrom: startingFrom.toString(),
        page: page.toString(),
        itemsPerPage: resultsPerPage.toString(),
        q: query,
        email: complainantSearch,
        assessor: assessor,
        showSpam: showSpam ? 1 : 0,
      });

      const records: Array<Record> = data.complaints.map((e) => {
        let category = '';
        switch (e.type) {
          case ComplaintTypeInt.Copyright:
            category = ComplaintType.Copyright;
            break;

          case ComplaintTypeInt.Inappropriate:
            category = ComplaintType.Inappropriate;
            break;

          case ComplaintTypeInt.Other:
            category = ComplaintType.Other;
            break;
        }

        return {
          id: e._id.toString(),
          category,
          companyName: e.companyName,
          fullName: e.fullName,
          submittedOrResolvedOn: dayjs(
            e.submittedOn ? e.submittedOn : e.resolvedOn
          ).format('YYYY-MM-DD'),
          geoScope: e.geoScope,
          assessor: e.assessor.contactPerson,
          isSpam: e.isSpam,
        };
      });

      setRecordsObject({
        records,
        totalPages: data.totalPages,
        totalCount: data.totalCount,
      });
    };
    getPublicComplaints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    order,
    orderBy,
    page,
    resultsPerPage,
    category,
    network,
    scope,
    timePeriod,
    debouncedQuery,
    complainantSearch,
    showSpam,
  ]);

  return (
    <>
      <div className="assessor-detail-header d-flex px-48 py-12 justify-content-between">
        <div className="assessor-detail-header-left-section d-flex align-items-center text-white">
          <span
            className="link-design cursor-pointer assessor-detail-header-text"
            onClick={() => navigate('/assessors')}
          >
            &lt;-
          </span>
          <span className="assessor-detail-header-text ml-24">
            Assessor: {assessorData?.businessName}
          </span>
        </div>
        <div
          className="assessor-detail-right-section d-flex align-items-center text-white assessor-detail-toggle cursor-pointer"
          {...getToggleProps()}
        >
          {isExpanded ? 'Hide details' : 'Show details'}
          <span className="ml-12">
            {isExpanded ? (
              <img alt="expand-less" src={icons.upArrow} />
            ) : (
              <img alt="expand-more" src={icons.downArrow} />
            )}
          </span>
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div className="assessor-detail-body d-flex justify-content-between px-48 py-24 text-white collapsible">
          <div className="assessor-detail-body-left-section align-items-center text-white">
            <div className="d-flex flex-column justify-content-between h-100">
              <div className="d-flex flex-column">
                <div className="assessor-detail-body-reports-title">
                  Reports assessed
                </div>
                <div className="assessor-detail-body-reports-num">
                  {recordsObject.totalCount || 0}
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="assessor-detail-body-reports-register">
                  Registered on{' '}
                  {dayjs(assessorData?.createdAt).format('MM-DD-YYYY')}
                </div>
                <div className="assessor-detail-body-reports-register">
                  Deactivated on{' '}
                  {dayjs(assessorData?.deletedAt).format('MM-DD-YYYY')}
                </div>
              </div>
            </div>
          </div>
          <div className="assessor-detail-body-center-section d-flex justify-content-between flex-column text-white mr-24">
            <div>
              {assessorData?.businessName} is an assessor on the Filecoin
              network. They have the ability to review inbound complaints
              regarding content, and apply criteria to make an assessment. They
              may also choose to add submitted CIDs to filter lists, which
              storage providers can subscribe to in BitScreen if they choose.
            </div>
            <a
              href="https://github.com/Murmuration-Labs/looking-glass-docs/blob/main/how-are-complaints-assessed.md"
              target="_blank"
              className="text-white"
            >
              Find out more
            </a>
          </div>
          <div className="assessor-detail-body-right-section d-flex flex-column text-white pl-48">
            <div className="assessor-detail-right-section-item mb-10 d-flex">
              <div className="assessor-detail-right-section-item-icon">
                <img alt="Business Icon" src={icons.business} />
              </div>
              <div className="assessor-detail-right-section-item-data">
                <a href={assessorData?.website}>{assessorData?.businessName}</a>
              </div>
            </div>
            <div className="assessor-detail-right-section-item mb-10 d-flex">
              <div className="assessor-detail-right-section-item-icon">
                <img alt="Email Icon" src={icons.envelope} />
              </div>
              <div className="assessor-detail-right-section-item-data">
                <a href={`mailto:${assessorData?.email}`}>
                  {assessorData?.email}
                </a>
              </div>
            </div>
            <div className="assessor-detail-right-section-item d-flex">
              <div className="assessor-detail-right-section-item-icon">
                <img alt="Location Icon" src={icons.location} />
              </div>
              <div className="assessor-detail-right-section-item-data">
                {assessorData?.address}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-records-container d-flex flex-column">
        <div className="table-records-header align-items-center d-flex justify-content-between text-white px-48 py-16 w-full gap-4">
          <InputGroup className="table-records-search-input-group">
            <InputGroup.Text
              className="table-records-search-icon-container pt-8 pb-8 pl-12 pr-10"
              id="search"
            >
              <img alt="search-icon" src={icons.search} />
            </InputGroup.Text>
            <Form.Control
              className="table-records-search-input pl-0 no-outline no-box-shadow"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />
            {/* <InputGroup.Text
                className="remove-icon-container pt-8 pb-8 pl-12 pr-10"
                id="search"
                >
                {searchQuery && (
                  <img
                  onClick={() => {
                    if (searchQuery) setSearchQuery('');
                  }}
                  className="remove-icon c-pointer no-text-select"
                  src={icons.removeList}
                  />
                  )}
                </InputGroup.Text> */}
          </InputGroup>
          <div className="table-records-header-dropdown-section d-flex gap-3">
            <div className="table-records-header-dropdown d-flex align-items-center">
              <div className="table-records-header-dropdown-text">
                Category:
              </div>
              <div className="table-records-header-dropdown-input">
                <Dropdown className="">
                  <Dropdown.Toggle className="toggle-dropdown-button fs-14 lh-20 fw-600 py-6 px-16">
                    {category}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="toggle-menu">
                    <Dropdown.Item
                      onClick={() => setCategory(ComplaintTypeFilter.All)}
                    >
                      <div className="custom-menu-item menu-item-created">
                        {ComplaintTypeFilter.All}
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setCategory(ComplaintTypeFilter.Copyright)}
                    >
                      <div className="custom-menu-item menu-item-created">
                        {ComplaintTypeFilter.Copyright}
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        setCategory(ComplaintTypeFilter.Inappropriate)
                      }
                    >
                      <div className="custom-menu-item menu-item-created">
                        {ComplaintTypeFilter.Inappropriate}
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setCategory(ComplaintTypeFilter.Other)}
                    >
                      <div className="custom-menu-item menu-item-created">
                        {ComplaintTypeFilter.Other}
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="table-records-header-dropdown d-flex align-items-center">
              <div className="table-records-header-dropdown-text">Network:</div>
              <div className="table-records-header-dropdown-input">
                <Dropdown className="">
                  <Dropdown.Toggle className="toggle-dropdown-button fs-14 lh-20 fw-600 py-6 px-16">
                    {network}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="toggle-menu">
                    <Dropdown.Item onClick={() => setNetwork('All')}>
                      <div className="custom-menu-item menu-item-created">
                        All
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setNetwork(NetworkType.Filecoin)}
                    >
                      <div className="custom-menu-item menu-item-created">
                        {NetworkType.Filecoin}
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setNetwork(NetworkType.IPFS)}>
                      <div className="custom-menu-item menu-item-created">
                        {NetworkType.IPFS}
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="table-records-header-dropdown d-flex align-items-center">
              <div className="table-records-header-dropdown-text">Scope:</div>
              <div>
                <Select
                  components={{ ...animatedComponents, DropdownIndicator }}
                  isMulti
                  ref={selectInputRef}
                  onChange={(e: any) => {
                    setScope(e);
                  }}
                  options={countryList().getData()}
                  placeholder="Scope"
                  styles={customStyles}
                />
              </div>
            </div>
            <div className="table-records-header-dropdown d-flex align-items-center">
              <div className="table-records-header-dropdown-text">
                Time period:
              </div>
              <div className="table-records-header-dropdown-input">
                <Dropdown className="">
                  <Dropdown.Toggle className="toggle-dropdown-button fs-14 lh-20 fw-600 py-6 px-16">
                    {timePeriod}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="toggle-menu">
                    <Dropdown.Item
                      onClick={() => setTimePeriod(TimePeriodEnum.days7)}
                    >
                      <div className="custom-menu-item menu-item-created">
                        {TimePeriodEnum.days7}
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setTimePeriod(TimePeriodEnum.days30)}
                    >
                      <div className="custom-menu-item menu-item-created">
                        {TimePeriodEnum.days30}
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setTimePeriod(TimePeriodEnum.months3)}
                    >
                      <div className="custom-menu-item menu-item-created">
                        {TimePeriodEnum.months3}
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setTimePeriod(TimePeriodEnum.months6)}
                    >
                      <div className="custom-menu-item menu-item-created">
                        {TimePeriodEnum.months6}
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setTimePeriod(TimePeriodEnum.year)}
                    >
                      <div className="custom-menu-item menu-item-created">
                        {TimePeriodEnum.year}
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="table-records-header-dropdown d-flex align-items-center">
              <div
                onClick={() => {
                  setTimePeriod(TimePeriodEnum.days30);
                  setScope([
                    {
                      label: 'Global',
                      value: 'Global',
                    },
                  ]);
                  setPage(1);
                  setOrderBy('_id');
                  setOrder('desc');
                  setResultsPerPage(15);
                  setAssessorSearch(undefined);
                  setComplainantSearch(undefined);
                  setQuery('');
                  setCategory(ComplaintTypeFilter.All);
                  selectInputRef.current.setValue(null);
                }}
                className="table-records-header-dropdown-text clear-filters-button d-flex justify-content-center align-items-center text-align-center height-fit-content"
              >
                Clear filters
              </div>
            </div>
            <div className="table-records-header-dropdown d-flex align-items-center">
              <div className="table-records-header-dropdown-text">Spam:</div>
              <AntSwitch
                checked={showSpam}
                {...getToggleProps({
                  onClick: () => setShowSpam((showSpam) => !showSpam),
                })}
                size="medium"
                name="show-spam"
              />
            </div>
          </div>
        </div>
        <TableContent
          records={recordsObject.records}
          totalPages={recordsObject.totalPages}
          setPage={setPage}
          page={page}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          order={order}
          setOrder={setOrder}
          resultsPerPage={resultsPerPage}
          setResultsPerPage={setResultsPerPage}
          recordsFilter={{
            complainantEmail: complainantSearch,
            assessorId: assessorSearch,
            query: debouncedQuery,
            category,
            orderBy,
            order,
            scope,
            timePeriod,
            page,
            resultsPerPage,
          }}
          recordsIdsArray={recordsObject.records.map((e) => e.id)}
        />
      </div>
    </>
  );
};
